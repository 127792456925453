import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import * as Select from "@radix-ui/react-select";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "../../components/loading/loading.js";
import { Button } from "../../components/button/button.js";
import { Share } from "../../components/share/share.js";
import { PdfViewer } from "../../components/pdf-viewer/pdf-viewer.js";
import { People } from "../../components/people";
import { Protected } from "../../components/protected";

import { Amend } from "./components";
import { ReactComponent as CheckInImage } from "./check-in.svg";

const fetchProject = async ({
  setProject,
  id,
  setIsLoading = () => {},
  cache,
  getAccessTokenSilently,
  onError = () => {},
}) => {
  if (cache.current?.project) {
    setProject(cache.current.project);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const project = await response.json();

    if (response.status === 404) {
      onError();
    }

    setProject({
      ...project,
      project_files: (project?.project_files || []).filter(
        ({ state }) => state !== `deleted`,
      ),
    });
    cache.current.project = project;
  } catch (_) {
    setIsLoading(false);
    return;
  }

  setIsLoading(false);
};

const fetchCheckIn = async ({
  setCheckIn,
  id,
  projectId,
  setIsLoading = () => {},
  getAccessTokenSilently,
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/checkins/${id}`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const checkIn = await response.json();

    setCheckIn(checkIn);
  } catch (_) {
    setIsLoading(false);
    return;
  }

  setIsLoading(false);
};

const linkResource = async ({
  fields,
  id,
  projectId,
  getAccessTokenSilently,
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/checkins/${id}/primary-resource`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        body: JSON.stringify({
          ...fields,
        }),
      },
    );

    await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/files/${fields.primary_resource_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        body: JSON.stringify({
          file_type: `presentation`,
        }),
      },
    );

    if (!response.ok) {
      throw new Error();
    }
  } catch (_) {
    return false;
  }

  return true;
};

export const CheckIn = ({ setViewData, cache, session, onError }) => {
  const defaultFields = {
    primary_resource_id: ``,
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [fields, setFields] = useState(defaultFields);
  const [project, setProject] = useState({});
  const [checkIn, setCheckIn] = useState([]);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const { id, projectId } = useParams();
  const handleChange = (name, value) => {
    setFields((previousFields) => ({ ...previousFields, [name]: value }));
  };
  const { getAccessTokenSilently } = useAuth0();
  const handleAdd = async (event) => {
    event.preventDefault();
    setIsLoading1(true);
    const added = await linkResource({
      fields,
      id,
      projectId,
      getAccessTokenSilently,
    });
    if (added) {
      await fetchCheckIn({
        setCheckIn,
        id,
        projectId,
        setIsLoading: setIsLoading1,
        getAccessTokenSilently,
      });
      setFields(defaultFields);
      setIsLoading1(false);
      setModalOpen(false);
    } else {
      setIsLoading1(false);
    }
  };
  const fileFromId = (id) => {
    return (project?.project_files || []).find((file) => id === file.id);
  };
  const primaryResource = fileFromId(checkIn.primary_resource_id);
  const isLoading = isLoading1 || isLoading2;

  useEffect(() => {
    fetchProject({
      setProject,
      id: projectId,
      cache,
      setIsLoading: setIsLoading1,
      getAccessTokenSilently,
      onError,
    });
    fetchCheckIn({
      setCheckIn,
      id,
      projectId,
      setIsLoading: setIsLoading2,
      getAccessTokenSilently,
    });
  }, [setProject, id, cache, projectId, getAccessTokenSilently, onError]);

  useEffect(() => {
    setViewData({ title: project.title, id: projectId });
  }, [setViewData, project, projectId]);

  const checkInFolderPath = (project?.project_files || []).find(
    ({ id }) => id === checkIn.resources_folder_id,
  )?.folder_path;
  const sanitizedCheckInFolderPath = `${checkInFolderPath}/`;

  return (
    <>
      <div className="flex p-6 flex-col gap-5">
        <ul className="flex gap-2 items-center text-sm text-stone-500">
          <li className="flex gap-1 items-center text-stone-800">
            <Link to="/" className="hover:underline">
              Projects
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link
              to={`/projects/${projectId}?orgName=${encodeURIComponent(
                session.orgNiceName,
              )}`}
              className="hover:underline"
            >
              {project.title}
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link to={`/check-ins/${projectId}`} className="hover:underline">
              Check-Ins
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link
              to={`/check-ins/${projectId}/${id}`}
              className="hover:underline"
            >
              {checkIn.deliverable}
            </Link>
          </li>
        </ul>
        <div className="flex grow items-center gap-3 align-">
          <h1 className="text-3xl font-medium">{checkIn.deliverable}</h1>
          <div
            className={`rounded-xl font-medium px-2 py-1 inline-block text-xs bg-[#EFF6FF] text-[#060E23] border-solid border-[#E6ECFF]`}
          >
            {checkIn.commencement_date}
          </div>
          <Protected requiredRoles={[`Pentatonic`]}>
            <Amend
              {...checkIn}
              projectId={projectId}
              refetch={() =>
                fetchCheckIn({
                  setCheckIn,
                  id,
                  projectId,
                  setIsLoading: setIsLoading2,
                  getAccessTokenSilently,
                })
              }
              isViewing
            />
          </Protected>
          {isLoading && <Loading large />}
          {primaryResource ? (
            <div className="ml-auto flex items-center gap-5">
              <People projectId={projectId} orgId={session.orgId} />
              {primaryResource && (
                <a
                  href={`${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/files/${primaryResource.id}`}
                >
                  <i className="material-symbols-rounded text-xl">download</i>
                </a>
              )}
              <Share />
            </div>
          ) : (
            <div className="ml-auto">
              <div className="flex items-center gap-6">
                <Share />
                <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
                  <Protected requiredRoles={[`Pentatonic`]}>
                    <Dialog.Trigger asChild>
                      <Button icon="link">Link Resource</Button>
                    </Dialog.Trigger>
                  </Protected>
                  <Dialog.Portal>
                    <Dialog.Overlay className="fixed bg-black/20 inset-0" />
                    <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
                      <div className="flex border-b border-solid border-blue-50 p-4 items-start">
                        <div className="flex grow flex-col">
                          <Dialog.Title className="text-lg">
                            Link Resource
                          </Dialog.Title>
                          <div className="text-stone-700 text-sm">
                            Select a PDF to present at this check-in
                          </div>
                        </div>
                        <Dialog.Close tabIndex="-1">
                          <i className="material-symbols-rounded text-xl">
                            close
                          </i>
                        </Dialog.Close>
                      </div>
                      <form onSubmit={handleAdd}>
                        <div className="flex flex-col p-4 gap-4">
                          <div className="flex flex-col gap-1">
                            <label
                              className="text-sm text-stone-800"
                              htmlFor="add_resource"
                            >
                              Resource
                            </label>
                            <Select.Root
                              onValueChange={(value) =>
                                handleChange(`primary_resource_id`, value)
                              }
                              value={fields.primary_resource_id}
                            >
                              <Select.Trigger
                                aria-label="Link Resource"
                                className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300 flex justify-between items-center"
                              >
                                <Select.Value placeholder="Please select" />
                                <Select.Icon className="flex">
                                  <i className="material-symbols-rounded text-xl text-stone-600 leading-none">
                                    expand_more
                                  </i>
                                </Select.Icon>
                              </Select.Trigger>
                              <Select.Portal>
                                <Select.Content>
                                  <Select.ScrollUpButton>
                                    <i className="material-symbols-rounded text-xl text-stone-600">
                                      expand_less
                                    </i>
                                  </Select.ScrollUpButton>
                                  <Select.Viewport className="bg-white rounded-lg shadow-3xl border border-blue-50">
                                    {(project?.project_files || [])
                                      .filter(
                                        ({ name, state, folder_path }) =>
                                          state !== `deleted` &&
                                          !folder_path.startsWith(
                                            `/ah7rhd-sow`,
                                          ) &&
                                          name.endsWith(`.pdf`) &&
                                          folder_path ===
                                            sanitizedCheckInFolderPath,
                                      )
                                      .map(({ id: fileId, name }) => (
                                        <Select.Item
                                          key={fileId}
                                          value={fileId}
                                          className="p-2 text-sm flex items-center gap-2 cursor-pointer"
                                        >
                                          <Select.ItemText>
                                            {decodeURIComponent(name)}
                                          </Select.ItemText>
                                          <Select.ItemIndicator className="flex">
                                            <i className="material-symbols-rounded text-sm text-stone-600">
                                              done
                                            </i>
                                          </Select.ItemIndicator>
                                        </Select.Item>
                                      ))}
                                  </Select.Viewport>
                                  <Select.ScrollDownButton>
                                    <i className="material-symbols-rounded text-xl text-stone-600">
                                      expand_more
                                    </i>
                                  </Select.ScrollDownButton>
                                </Select.Content>
                              </Select.Portal>
                            </Select.Root>
                          </div>
                        </div>
                        <div className="flex justify-end border-t border-solid border-blue-50 p-4">
                          <Button
                            type="submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                          >
                            Link Resource
                          </Button>
                        </div>
                      </form>
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>
              </div>
            </div>
          )}
        </div>
      </div>
      {!isLoading && (
        <>
          {primaryResource ? (
            <PdfViewer
              url={`${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/files/${primaryResource.id}`}
            />
          ) : (
            <div className="flex items-center flex-col gap-3 border-t border-solid border-blue-50 py-16">
              <CheckInImage className="w-full mb-8" />
              <h2 className="text-stone-800 text-3xl font-medium">Check-In</h2>
              <div className="text-stone-500 text-base">
                <Protected
                  requiredRoles={[`Pentatonic`]}
                  fallback="This check-in is currently being worked on. Check back soon"
                >
                  Link a resource to this check-in to get started.
                </Protected>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
