import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import { useAuth0 } from "@auth0/auth0-react";

import { Loading } from "../../components/loading/loading.js";
import { Button } from "../../components/button/button.js";
import { Share } from "../../components/share/share.js";
import { PdfViewer } from "../../components/pdf-viewer/pdf-viewer.js";
import { People } from "../../components/people";
import { Protected } from "../../components/protected";

const fetchProject = async ({
  setProject,
  id,
  setIsLoading = () => {},
  cache,
  getAccessTokenSilently,
}) => {
  if (cache.current?.project) {
    setProject(cache.current.project);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const project = await response.json();

    setProject({
      ...project,
      project_files: (project?.project_files || []).filter(
        ({ state }) => state !== `deleted`,
      ),
    });
    cache.current.project = project;
  } catch (_) {
    setIsLoading(false);
    return;
  }

  setIsLoading(false);
};

const fetchCheckIns = async ({
  setCheckIns,
  id,
  setIsLoading = () => {},
  getAccessTokenSilently,
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/checkins`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const { checkins } = await response.json();
    setCheckIns(checkins || []);
  } catch (_) {
    setIsLoading(false);
    return;
  }

  setIsLoading(false);
};

export const PitchBack = ({ setViewData, cache, session }) => {
  const [project, setProject] = useState({});
  const [checkIns, setCheckIns] = useState([]);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const { id } = useParams();
  const checkIn =
    checkIns.find(({ checkin_type }) => checkin_type === `pitch-back`) || {};
  const fileFromId = (id) => {
    return (project?.project_files || []).find((file) => id === file.id);
  };
  const primaryResource = fileFromId(checkIn.primary_resource_id);
  const isLoading = isLoading1 || isLoading2;
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchProject({
      setProject,
      id,
      cache,
      setIsLoading: setIsLoading1,
      getAccessTokenSilently,
    });
    fetchCheckIns({
      setCheckIns,
      id,
      setIsLoading: setIsLoading2,
      getAccessTokenSilently,
    });
  }, [setProject, id, cache, getAccessTokenSilently]);

  useEffect(() => {
    setViewData({ title: project.title, id });
  }, [setViewData, project, id]);

  return (
    <>
      <div className="flex p-6 flex-col gap-5">
        <ul className="flex gap-2 items-center text-sm text-stone-500">
          <li className="flex gap-1 items-center text-stone-800">
            <Link to="/" className="hover:underline">
              Projects
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link
              to={`/projects/${id}?orgName=${encodeURIComponent(
                session.orgNiceName,
              )}`}
              className="hover:underline"
            >
              {project.title}
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link to={`/pitch-back/${id}`} className="hover:underline">
              Pitch Back
            </Link>
          </li>
        </ul>
        <div className="flex grow items-center gap-3 align-">
          <h1 className="text-3xl font-medium">Pitch Back</h1>
          {checkIn.commencement_date && (
            <>
              <div
                className={`rounded-xl font-medium px-2 py-1 inline-block text-xs bg-[#EFF6FF] text-[#060E23] border-solid border-[#E6ECFF]`}
              >
                {checkIn.commencement_date}
              </div>
              <Popover.Root>
                <Popover.Trigger>
                  <i className="material-symbols-rounded text-xl text-stone-600">
                    more_horiz
                  </i>
                </Popover.Trigger>
                <Popover.Portal>
                  <Popover.Content
                    className="rounded-lg shadow-3xl p-2 bg-white min-w-[12rem]"
                    collisionPadding={16}
                    sideOffset={8}
                  >
                    <ul>
                      <li>
                        <Link
                          to={`/check-ins/${id}/${checkIn.id}`}
                          className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium"
                        >
                          View Check-In
                        </Link>
                      </li>
                    </ul>
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </>
          )}
          {isLoading && <Loading large />}
          {primaryResource ? (
            <div className="ml-auto flex items-center gap-5">
              <People projectId={id} orgId={session.orgId} />
              {primaryResource && (
                <a
                  href={`${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${primaryResource.id}`}
                >
                  <i className="material-symbols-rounded text-xl">download</i>
                </a>
              )}
              <Share />
            </div>
          ) : (
            <div className="ml-auto flex items-center gap-5">
              <Share />
            </div>
          )}
        </div>
      </div>
      {!isLoading && (
        <>
          {primaryResource ? (
            <PdfViewer
              url={`${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${primaryResource.id}`}
            />
          ) : (
            <>
              {checkIn.commencement_date ? (
                <div className="flex items-center flex-col gap-3 border-t border-solid border-blue-50 py-16">
                  <h2 className="text-stone-800 text-3xl font-medium">
                    Pitch Back
                  </h2>
                  <div className="text-stone-500 text-base">
                    <Protected
                      requiredRoles={[`Pentatonic`]}
                      fallback="This Pitch Back is currently being worked on. Check back soon."
                    >
                      Go to the associated Check-In to manage the Pitch Back.
                    </Protected>
                  </div>
                  <Protected requiredRoles={[`Pentatonic`]}>
                    <Button
                      onClick={() => navigate(`/check-ins/${id}/${checkIn.id}`)}
                    >
                      Go to Check-In
                    </Button>
                  </Protected>
                </div>
              ) : (
                <div className="flex items-center flex-col gap-3 border-t border-solid border-blue-50 py-16">
                  <h2 className="text-stone-800 text-3xl font-medium">
                    Pitch Back
                  </h2>
                  <div className="text-stone-500 text-base">
                    <Protected
                      requiredRoles={[`Pentatonic`]}
                      fallback="This Pitch Back is currently being worked on. Check back soon."
                    >
                      Associate a Check-In with the Pitch Back to get started.
                    </Protected>
                  </div>
                  <Protected requiredRoles={[`Pentatonic`]}>
                    <Button onClick={() => navigate(`/check-ins/${id}`)}>
                      Go to Check-Ins
                    </Button>
                  </Protected>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
