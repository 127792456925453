import { useState } from "react";
import * as Toast from "@radix-ui/react-toast";

export const Share = () => {
  const [toastOpen, setToastOpen] = useState({ open: false });

  return (
    <>
      <button
        onClick={() => {
          const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}`;

          if (navigator.share) {
            navigator.share({ url });
          } else {
            navigator.clipboard.writeText(url);
            setToastOpen({
              open: true,
              type: `success`,
              title: `Share`,
              message: `The URL for this page has been copied to the clipboard successfully.`,
            });
          }
        }}
      >
        <i className="material-symbols-rounded text-2xl text-blue-700">share</i>
      </button>
      <Toast.Root
        open={toastOpen.open}
        onOpenChange={(open) => setToastOpen({ ...toastOpen, open })}
        className="fixed bottom-0 right-0 p-4 z-10"
      >
        <div className="rounded-lg shadow-4xl bg-white p-2 flex gap-3 max-w-[20rem] items-start border border-blue-50">
          <i
            className={`material-symbols-rounded text-lg leading-none ${
              toastOpen.type === `error` ? `text-[#c22960]` : `text-[#00cfa8]`
            }`}
          >
            {toastOpen.type === `error` ? `error` : `done`}
          </i>
          <div className="flex flex-col gap-1">
            <Toast.Title className="font-medium text-sm text-stone-800">
              {toastOpen.title}
            </Toast.Title>
            <Toast.Description className="text-xs text-stone-500 pb-1">
              {toastOpen.message}
            </Toast.Description>
          </div>
          <Toast.Close>
            <i className="material-symbols-rounded text-lg leading-none text-stone-300 hover:text-stone-600">
              close
            </i>
          </Toast.Close>
        </div>
      </Toast.Root>
    </>
  );
};
