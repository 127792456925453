import { useCallback, useEffect, useRef, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import * as Toast from "@radix-ui/react-toast";
import * as Toggle from "@radix-ui/react-toggle";
import { formatDistance } from "date-fns";
import Cookies from "js-cookie";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { MainNav } from "@pentatonic/components";
import {
  Home,
  HomeNavigation,
  Project,
  ProjectNavigation,
  Resources,
  CheckIns,
  PitchBack,
  CheckIn,
  Error,
  MaterialsProcurement,
} from "../pages";
import { PermissionsContext } from "../components";
import circularityCalculatorBackdropImage from "./circularity-calculator-backdrop.png";
import decommissioningBackdropImage from "./decommissioning-backdrop.png";
import projectsBackdropImage from "./projects-backdrop.png";
import materialsSourcingBackdropImage from "./materials-sourcing-backdrop.png";
import materialsProcurmentBackdropImage from "./materials-procurment-backdrop.png";

const ENVS = {
  "/local": `dev`,
  "/dev": `dev`,
  "/training": `staging`,
  "/demo": `demo`,
  "": `production`,
};

const { REACT_APP_CLIENT_ID, REACT_APP_AUTH_DOMAIN, REACT_APP_COOKIE_DOMAIN } =
  process.env;

const fetchNotificationSetting = async ({
  setNotificationSetting,
  getAccessTokenSilently,
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_URI_BASE_PATH}/api/notifications/email_opt`,
    {
      headers: {
        "Content-Type": `application/json`,
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    },
  );
  const notificationSetting = await response.json();
  setNotificationSetting(notificationSetting?.opt_in || false);
};

const fetchNotifications = async ({
  setNotifications,
  getAccessTokenSilently,
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_URI_BASE_PATH}/api/notifications`,
    {
      headers: {
        "Content-Type": `application/json`,
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    },
  );
  const notifications = await response.json();
  setNotifications(
    (notifications?.notifications || []).filter(
      ({ acknowledged }) => !acknowledged,
    ),
  );
};

const ErrorNavigation = ({ setIsErrorPage }) => {
  useEffect(() => {
    setIsErrorPage(true);
  }, [setIsErrorPage]);

  return null;
};

const Notification = ({ avatar, name, created, target_resource_uri }) => {
  return (
    <>
      <div className="">
        <img src={avatar} alt={name} className="w-3 h-3 rounded-full inline" />
        {` `}
        <span>{name}</span>
        {` `}
        <span>added a file</span>
        {` `}
        <span>
          {formatDistance(new Date(created), new Date(), {
            addSuffix: true,
          })}
        </span>
      </div>
      <div className="flex flex-grow justify-end gap-1">
        <a
          className="flex hover:underline"
          href={`${process.env.REACT_APP_URI_BASE_PATH}/api${target_resource_uri}`}
        >
          View
        </a>
      </div>
    </>
  );
};

const Notifications = ({ notifications }) => {
  return (
    <>
      <div className="font-medium px-2">Recent notifications</div>
      <ul className="max-h-[9.5rem] overflow-auto">
        {notifications.map(
          ({
            id: notificationId,
            avatar,
            name,
            target_resource_uri,
            created,
          }) => (
            <li
              key={notificationId}
              className="flex items-center gap-3 px-3 py-0.5"
            >
              <Notification
                avatar={avatar}
                name={name}
                created={created}
                target_resource_uri={target_resource_uri}
              />
            </li>
          ),
        )}
      </ul>
    </>
  );
};

const AvatarMenu = ({
  user,
  notificationSetting,
  handlePressChange,
  clientView,
  setClientView,
}) => {
  return (
    <>
      {user.baseIsInternal && (
        <li className="text-sm p-2 text-stone-800 flex gap-2 items-center">
          <div>Client view</div>
          <div className="ml-auto flex">
            <Toggle.Root
              aria-label="Toggle client view"
              className="bg-blue-50 rounded-full p-[2px] w-11 flex data-[state=on]:justify-end data-[state=on]:bg-blue-700 hover:bg-stone-400 data-[state=on]:hover:bg-stone-400"
              pressed={clientView}
              onPressedChange={setClientView}
            >
              <div className="bg-white rounded-full w-5 h-5" />
            </Toggle.Root>
          </div>
        </li>
      )}
      {Boolean(user.notifications.length) && (
        <li className="text-sm flex flex-col gap-1 my-1">
          <Notifications notifications={user.notifications} />
        </li>
      )}
      {notificationSetting !== undefined && (
        <li className="text-sm p-2 text-stone-800 flex gap-2 items-center">
          <i className="material-symbols-rounded text-xl leading-none text-stone-600">
            email
          </i>
          <div>Email notifications</div>
          <div className="ml-auto flex">
            <Toggle.Root
              aria-label="Toggle email notifications"
              className="bg-blue-50 rounded-full p-[2px] w-11 flex data-[state=on]:justify-end data-[state=on]:bg-blue-700 hover:bg-stone-400 data-[state=on]:hover:bg-stone-400"
              pressed={notificationSetting}
              onPressedChange={handlePressChange}
            >
              <div className="bg-white rounded-full w-5 h-5" />
            </Toggle.Root>
          </div>
        </li>
      )}
    </>
  );
};

const LandingPage = ({ user, logout, loginWithRedirect }) => {
  const [app] = window.location.host.split(`.`);
  const materialsProcurment = {
    name: "Materials Procurement",
    backdropImage: materialsProcurmentBackdropImage,
    icon: "pallet",
    text: "Effortlessly find and procure a diverse range of sustainable materials and packaging products. The platform offers a comprehensive database of vetted environmentally responsible suppliers. Place and track orders directly through the platform, as well as handle payments and manage essential documentation seamlessly.",
  };
  const apps = {
    "circularity-calculator": {
      name: "Circularity Calculator",
      backdropImage: circularityCalculatorBackdropImage,
      icon: "calculate",
      text: "Bring groundbreaking new concepts to life within our Circularity Calculator. Seamlessly import design files for interactive visualization and instant sustainability analysis.",
    },
    decommissioning: {
      name: "Take-back Program",
      backdropImage: decommissioningBackdropImage,
      icon: "box",
      text: "Connect end-of-life phases to your value chain with our Takeback Program module. Launch, manage, and optimize efficient reverse logistics for sustainable product reuse, material recovery, and overall decommissioning.",
    },
    "materials-sourcing": {
      name: "Materials Sourcing",
      backdropImage: materialsSourcingBackdropImage,
      icon: "conveyor_belt",
      text: "Explore sustainable and innovative materials using our Material Sourcing module. Make informed selections to optimize your products and packaging for circularity.",
    },
    "*": {
      name: "Projects",
      backdropImage: projectsBackdropImage,
      icon: "rocket_launch",
      text: "Curate new circular economy initiatives using our Projects module. Keep teams engaged, aligned and on schedule with secure, intuitive cloud-based management for effortless collaboration across functions, and with external partners.",
    },
    "material-procurement": materialsProcurment,
    "material-procurement-demo": materialsProcurment,
  };
  const { name, backdropImage, icon, text } = apps[app] || apps["*"];

  return (
    <div className="flex flex-col h-screen">
      <MainNav
        appName={name}
        user={user}
        handleSignIn={loginWithRedirect}
        handleSignOut={() =>
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}${process.env.REACT_APP_URI_BASE_PATH}`,
            },
          })
        }
        env={ENVS[process.env.REACT_APP_URI_BASE_PATH]}
      />
      <div
        className="flex flex-grow items-center justify-center bg-cover"
        style={{ backgroundImage: `url('${backdropImage}')` }}
      >
        <div className="w-full max-w-[34rem] flex items-center justify-center flex-col gap-5 p-10 mx-4 rounded-[1rem] shadow-3xl bg-gradient-to-br from-[#DAF2F9] via-[#F0DAF9] to-[#D5F8F1]">
          <div className="flex w-full gap-4 items-center">
            <div className="bg-blue-700 text-white w-8 h-8 rounded-[0.5rem] flex items-center justify-center">
              <i className="material-symbols-rounded text-xl leading-none">
                {icon}
              </i>
            </div>
            <div className="text-[#282F44] font-medium text-2xl">{name}</div>
          </div>
          <div className="text-[#464C64] text-sm leading-6">{text}</div>
          <div className="flex justify-between w-full">
            <a
              href="https://pentatonic.com/services"
              className="flex items-center gap-2 rounded-[0.5rem] border border-[#282F44] px-3.5 py-2 text-[#282F44] text-sm"
              target="_blank"
              rel="noreferrer"
            >
              <i className="material-symbols-rounded text-xl leading-none color-[#282F44]">
                open_in_new
              </i>
              <div>Learn More</div>
            </a>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScgoYZ4jbvWelbHoAX77swQ_nYfoXgDI5imeBDwmoiQ3CxrNw/viewform"
              className="flex items-center gap-2 rounded-[0.5rem] px-3.5 py-2 text-white text-sm font-medium bg-blue-700"
              target="_blank"
              rel="noreferrer"
            >
              Request a Demo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(
    window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : initialValue,
  );

  useEffect(() => {
    window.localStorage.setItem(key, storedValue);
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
};

const Main = () => {
  const [previousSignedIn, setPreviousSignedIn] = useState();
  const [notifications, setNotifications] = useState([]);
  const [notificationSetting, setNotificationSetting] = useState();
  const [clientView, setClientView] = useLocalStorage(`clientView`, false);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [viewData, setViewData] = useState({});
  const cache = useRef({});
  const [isErrorPage, setIsErrorPage] = useState(
    pathname.startsWith(`/error/`) ||
    Boolean(searchParams.get(`error`)) ||
    Boolean(searchParams.get(`error_description`)),
  );
  const isMaterialsProcurement = pathname.startsWith(`/materials-procurement`);
  const hasSecondaryNav = !isMaterialsProcurement;
  const [app] = window.location.host.split(`.`);
  const isProduction = process.env.REACT_APP_URI_BASE_PATH === ``;
  const isLandingPage =
    [
      `circularity-calculator`,
      `material-procurement`,
      `material-procurement-demo`,
    ].includes(app) ||
    (isProduction && [`decommissioning`, `materials-sourcing`].includes(app));

  const {
    isLoading,
    user,
    error,
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const errorInUrl = searchParams.get(`error`);

  const handlePressChange = async (state) => {
    await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/notifications/email_opt`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        body: JSON.stringify({ opt_in: state }),
      },
    );
    setNotificationSetting(state);
  };
  const handleSigninSuccess = (user) => {
    fetch(`${process.env.REACT_APP_URI_BASE_PATH}/api/user`, {
      method: `PUT`,
      headers: {
        "Content-Type": `application/json`,
      },
      body: JSON.stringify(user),
    });
  };
  const handleSigninFailure = useCallback(() => {
    const noOrg =
      searchParams
        .get(`error_description`)
        ?.startsWith(`client requires organization`) || !user._orgs;

    if (noOrg) {
      fetch(`${process.env.REACT_APP_URI_BASE_PATH}/api/no-org`);
    }
  }, [searchParams, user]);
  const ptrt = searchParams.get(`ptrt`);

  useEffect(() => {
    if (previousSignedIn === undefined && isAuthenticated) {
      setPreviousSignedIn(true);
      handleSigninSuccess(user);
    }
  }, [isAuthenticated, previousSignedIn, user]);

  useEffect(() => {
    if (
      previousSignedIn === undefined &&
      !isAuthenticated &&
      (error || errorInUrl)
    ) {
      setPreviousSignedIn(false);
      handleSigninFailure();
    }
  }, [
    error,
    handleSigninFailure,
    isAuthenticated,
    previousSignedIn,
    errorInUrl,
  ]);

  useEffect(() => {
    if (
      previousSignedIn === undefined &&
      isAuthenticated &&
      user &&
      !user._orgs
    ) {
      setPreviousSignedIn(false);
      handleSigninFailure();
    }
  }, [handleSigninFailure, isAuthenticated, previousSignedIn, user]);

  useEffect(() => {
    if (isAuthenticated && !isErrorPage && !isLandingPage) {
      fetchNotificationSetting({
        setNotificationSetting,
        getAccessTokenSilently,
      });
      fetchNotifications({ setNotifications, getAccessTokenSilently });
    }
  }, [
    isErrorPage,
    searchParams,
    getAccessTokenSilently,
    isAuthenticated,
    isLandingPage,
  ]);

  useEffect(() => {
    setIsErrorPage(
      pathname.startsWith(`/error/`) ||
      Boolean(searchParams.get(`error`)) ||
      (user && !user._orgs),
    );
  }, [pathname, searchParams, user]);

  useEffect(() => {
    const updateAccessTokenCookie = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();

        Cookies.set("token", accessToken, {
          path: process.env.REACT_APP_URI_BASE_PATH || `/`,
        });
      }
    };

    updateAccessTokenCookie();
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (user && !user._org) {
      setIsErrorPage(true);
    }
  }, [user]);

  const onError = useCallback(() => setIsErrorPage(true), [setIsErrorPage]);

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated && !isLandingPage && !isErrorPage) {
    loginWithRedirect();

    return null;
  }

  if (ptrt && !errorInUrl) {
    window.location.assign(ptrt);

    return null;
  }

  const session = {
    ...user,
    avatar: user?.picture,
    orgNiceName: user?._org_nice_name,
    orgId: user?.org_id,
    orgName: user?._org_name,
    siblingOrgs:
      user?._sibling_orgs
        ?.split(` `)
        .filter(Boolean)
        .map((item) => {
          const [id, name, niceName] = item.split(`:`).map(decodeURIComponent);

          return { id, name, niceName };
        }) || [],
    permissions: clientView
      ? user?._permissions
        .split(`,`)
        .filter((permission) => permission !== `Pentatonic`)
      : user?._permissions.split(`,`),
    baseIsInternal: user?._permissions.includes(`Pentatonic`),
    isInternal: !clientView && user?._permissions.includes(`Pentatonic`),
  };

  if (error) {
    console.error(error);
  }

  if (isErrorPage) {
    return (
      <Error
        type={pathname.replace(`/error/`, ``)}
        logout={logout}
        session={session}
      />
    );
  }

  if (isLandingPage) {
    return (
      <LandingPage
        logout={logout}
        loginWithRedirect={loginWithRedirect}
        {...(isAuthenticated && { user: session })}
      />
    );
  }

  if (pathname.startsWith(`/logout`)) {
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}${process.env.REACT_APP_URI_BASE_PATH}`,
      },
    });

    return null;
  }

  return (
    <PermissionsContext.Provider value={session?.permissions}>
      <MainNav
        appName={isMaterialsProcurement ? `Materials Procurement` : `Projects`}
        user={{ ...session, notifications }}
        AvatarMenuSlot={
          <AvatarMenu
            user={{ ...session, notifications }}
            notificationSetting={notificationSetting}
            handlePressChange={handlePressChange}
            clientView={clientView}
            setClientView={setClientView}
          />
        }
        handleSignOut={() =>
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}${process.env.REACT_APP_URI_BASE_PATH}`,
            },
          })
        }
        env={ENVS[process.env.REACT_APP_URI_BASE_PATH]}
      />
      <div className="text-stone-800 flex">
        <div
          className={`min-w-[18rem] max-w-[18rem] p-6 border-r border-solid border-blue-50 min-h-[calc(100dvh-3.5rem-1px)] gap-6 flex-col ${isErrorPage || pathname === `/` || !hasSecondaryNav
              ? `hidden`
              : `flex`
            }`}
        >
          <Routes>
            <Route path="/" element={<HomeNavigation />} />
            <Route
              path="/projects/:id"
              element={
                <ProjectNavigation viewData={viewData} session={session} />
              }
            />
            <Route
              path="/resources/:id*"
              element={
                <ProjectNavigation viewData={viewData} session={session} />
              }
            />
            <Route
              path="/check-ins/:id"
              element={
                <ProjectNavigation viewData={viewData} session={session} />
              }
            />
            <Route
              path="/pitch-back/:id"
              element={
                <ProjectNavigation viewData={viewData} session={session} />
              }
            />
            <Route
              path="/check-ins/:projectId/:id"
              element={
                <ProjectNavigation viewData={viewData} session={session} />
              }
            />
            <Route path="/materials-procurement" />
            <Route
              path="*"
              element={<ErrorNavigation setIsErrorPage={setIsErrorPage} />}
            />
          </Routes>
        </div>
        <div className="flex grow flex-col">
          <Routes>
            <Route path="/" element={<Home session={session} />} />
            <Route
              path="/projects/:id"
              element={
                <Project
                  setViewData={setViewData}
                  cache={cache}
                  session={session}
                  onError={onError}
                />
              }
            />
            <Route
              path="/resources/:id/*"
              element={
                <Resources
                  setViewData={setViewData}
                  cache={cache}
                  session={session}
                />
              }
            />
            <Route
              path="/check-ins/:id"
              element={
                <CheckIns
                  setViewData={setViewData}
                  cache={cache}
                  session={session}
                />
              }
            />
            <Route
              path="/pitch-back/:id"
              element={
                <PitchBack
                  setViewData={setViewData}
                  cache={cache}
                  session={session}
                />
              }
            />
            <Route
              path="/check-ins/:projectId/:id"
              element={
                <CheckIn
                  setViewData={setViewData}
                  cache={cache}
                  session={session}
                  onError={onError}
                />
              }
            />
            <Route
              path="/materials-procurement"
              element={<MaterialsProcurement session={session} />}
            />
            <Route path="/error/:type" element={<Error />} />
            <Route path="*" element={<Error type="not-found" />} />
          </Routes>
        </div>
      </div>
    </PermissionsContext.Provider>
  );
};

export const App = () => {
  let ptrt = new URL(window.location).searchParams.get(`ptrt`);

  if (!ptrt) {
    ptrt = `${window.location.origin}${window.location.pathname}${window.location.search}`;
  }

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH_DOMAIN}
      clientId={REACT_APP_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${process.env.REACT_APP_URI_BASE_PATH
          }?ptrt=${encodeURIComponent(ptrt)}`,
        audience: `https://app.pentatonic.com/api`,
      }}
      cookieDomain={REACT_APP_COOKIE_DOMAIN}
      cacheLocation="localstorage"
    >
      <BrowserRouter basename={process.env.REACT_APP_URI_BASE_PATH}>
        <Toast.Provider swipeDirection="right" duration={Infinity}>
          <Main />
          <Toast.Viewport />
        </Toast.Provider>
      </BrowserRouter>
    </Auth0Provider>
  );
};
