import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { v4 as uuid } from "uuid";
import { useAuth0 } from "@auth0/auth0-react";

import { Button } from "../../../../components/button/button.js";

import { uploadFile } from "../../utils";

export const ManageDirectory = ({ id, path, onCreated }) => {
  const defaultFields = { name: `` };
  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState(defaultFields);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (name, value) => {
    setFields((previousFields) => ({ ...previousFields, [name]: value }));
  };
  const { getAccessTokenSilently } = useAuth0();
  const handleAdd = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const file = new File([` `], `${uuid()}.__keep`, { type: "text/plain" });

    try {
      await uploadFile({
        id,
        file,
        path: `${path}${encodeURIComponent(fields.name)}`,
        getAccessTokenSilently,
      });
      await onCreated();
      setOpen(false);
      setFields(defaultFields);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      alert(`Failed to upload the file. Please try again.`);

      return;
    }

    setIsLoading(false);
  };

  return (
    <>
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger asChild>
          <button className="flex">
            <i className="material-symbols-rounded text-xl leading-none hover:text-stone-600">
              create_new_folder
            </i>
          </button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed bg-black/20 inset-0" />
          <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
            <div className="flex border-b border-solid border-blue-50 p-4">
              <Dialog.Title className="flex grow text-lg">
                Add Folder
              </Dialog.Title>
              <Dialog.Close tabIndex="-1">
                <i className="material-symbols-rounded text-xl">close</i>
              </Dialog.Close>
            </div>
            <form onSubmit={handleAdd}>
              <div className="flex flex-col p-4 gap-4">
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-stone-800" htmlFor="add_name">
                    Folder name
                  </label>
                  <input
                    type="text"
                    placeholder="Name of the folder"
                    id="add_name"
                    required
                    onChange={(event) =>
                      handleChange(`name`, event.target.value)
                    }
                    value={fields.name}
                    className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                    maxLength="256"
                    tabIndex="0"
                  />
                </div>
              </div>
              <div className="flex justify-end border-t border-solid border-blue-50 p-4">
                <Button
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Create
                </Button>
              </div>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
