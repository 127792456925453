import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import { Button } from "../../components/button/button.js";

import forbiddenImage from "./forbidden.png";
import notFoundImage from "./not-found.png";
import restrictedImage from "./restricted.png";
import { ReactComponent as LogoImage } from "./logo.svg";

const types = {
  unauthorized: {
    title: `Forbidden`,
    image: forbiddenImage,
    message: () => `Sorry, you don’t have access to this page.`,
    cta: ({ navigate }) => ({
      name: `Back to Projects`,
      action: () => navigate(`/`),
    }),
  },
  "not-found": {
    title: `Page Not Found`,
    image: notFoundImage,
    message: () =>
      `Sorry, this page doesn’t appear to exist. Perhaps it's been turned into something else?`,
    cta: ({ navigate }) => ({
      name: `Back to Projects`,
      action: () => navigate(`/`),
    }),
  },
  "no-org": {
    title: `Access requested`,
    message: () =>
      `Your request to join the platform has been noted, you will be authorised shortly.`,
  },
  "wrong-org": {
    title: `Incorrect Organisation`,
    image: restrictedImage,
    message: ({ orgName }) =>
      `To view this document you need to log out and switch to the ${orgName} organisation.`,
    cta: ({ logout }) => ({
      name: `Log Out Now`,
      action: () =>
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}${process.env.REACT_APP_URI_BASE_PATH
              }?ptrt=${encodeURIComponent(
                `${window.location.origin}${window.location.pathname}${window.location.search}`,
              )}`,
          },
        }),
    }),
    secondaryCta: ({ navigate }) => ({
      name: `Cancel`,
      action: () => navigate(`/`),
    }),
    imageIsSmall: true,
  },
  "chose-personal": {
    title: `Personal accounts not supported`,
    message: () => `You must select an organisation to use this app.`,
    cta: ({ logout }) => ({
      name: `Select organisation`,
      action: () =>
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}${process.env.REACT_APP_URI_BASE_PATH}`,
          },
        }),
    }),
  },
};

export const Error = ({ type, logout, session }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const noOrg =
    searchParams
      .get(`error_description`)
      ?.startsWith(`client requires organization`) || !session._orgs;
  const orgName = searchParams.get(`orgName`);
  const navigate = useNavigate();
  let errorKey;

  if (
    /^\/projects\/[a-z,0-9,-]+|\/check-ins\/[a-z,0-9,-]+\/[a-z,0-9,-]+$/.test([
      pathname,
    ]) &&
    orgName &&
    orgName !== session._org_nice_name
  ) {
    errorKey = `wrong-org`;
  } else if (session && session._orgs && !session._org) {
    errorKey = `chose-personal`;
  } else {
    errorKey = noOrg ? `no-org` : type || params.type;
  }

  const { title, image, message, cta, imageIsSmall, secondaryCta } =
    types[errorKey] || types[`not-found`];

  return (
    <div
      className="flex p-6 flex-col items-center justify-center"
      style={{
        background: `linear-gradient(135deg, rgba(181,199,243,1) 0%, rgba(255,255,255,1) 50%, rgba(181,199,243,1) 100%)`,
        minHeight: `100dvh`,
      }}
    >
      <LogoImage className="w-full mb-6" />
      {image && (
        <img
          src={image}
          alt={title}
          className={`${imageIsSmall ? `w-52` : `w-96`} mix-blend-darken`}
        />
      )}
      <div className="flex items-center flex-col gap-4 py-8 w-full max-w-sm">
        <h2 className="text-stone-800 text-2xl font-medium">{title}</h2>
        <div className="text-stone-700 text-base text-center">
          {message({ orgName })}
        </div>
        <div className="flex gap-6 mt-3">
          {secondaryCta && (
            <Button onClick={secondaryCta({ navigate }).action} secondary>
              {secondaryCta({ navigate }).name}
            </Button>
          )}
          {cta && (
            <Button onClick={cta({ navigate, logout }).action}>
              {cta({ navigate, logout }).name}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
