import { useRef, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { useAuth0 } from "@auth0/auth0-react";
import * as Select from "@radix-ui/react-select";
import { v4 as uuid } from "uuid";
import { uploadFile } from "../../pages/resources/utils";
import { Button } from "../button";

export const UploadFile = ({
  id,
  trigger,
  type,
  isExisting = false,
  basePath = `/`,
  onFileUploaded = () => {},
  disabled = false,
  name = `File`,
  filePrefixList = [],
  currentFile,
}) => {
  const file = useRef();
  const cancelFileUpload = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilePrefix, setSelectedFilePrefix] = useState(
    filePrefixList[0]?.value,
  );
  const [holdingFile, setHoldingFile] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [percent, setPercent] = useState(0);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const handleUploadCancel = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current();
      setPercent(0);
      setIsUploadLoading(false);
      file.current.value = ``;
    }
  };
  const handleFileChange = async () => {
    setPercent(1);
    setIsUploadLoading(true);

    try {
      if (!isExisting) {
        await uploadFile({
          id,
          file: new File([` `], `${uuid()}.__keep`, { type: `text/plain` }),
          path: `${basePath}/holding`,
          getAccessTokenSilently,
        });
        await uploadFile({
          id,
          file: new File([` `], `${uuid()}.__keep`, { type: `text/plain` }),
          path: basePath,
          getAccessTokenSilently,
        });
      }

      const fileId = await uploadFile({
        id,
        file: file.current.files[0],
        name: `${uuid()}${selectedFilePrefix ? `-${selectedFilePrefix}` : ``}-${encodeURIComponent(file.current.files[0].name)}`,
        path: `${basePath}/holding`,
        setPercent,
        cancelFileUpload,
        getAccessTokenSilently,
      });

      setPercent(0);
      setHoldingFile({ id: fileId, name: file.current.files[0].name });
    } catch (error) {
      console.error(error);
      setIsUploadLoading(false);
      file.current.value = ``;
      alert(`Failed to upload the file. Please try again.`);
      return;
    }

    setIsUploadLoading(false);
    file.current.value = ``;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (holdingFile) {
      await fetch(
        `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${holdingFile.id}`,
        {
          method: `PATCH`,
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
          body: JSON.stringify({
            folder_path: `${basePath}/`,
            file_type: type,
          }),
        },
      );

      onFileUploaded();
      setIsLoading(false);
      setModalOpen(false);
      setHoldingFile();
    }

    if (filePrefixList.length && !holdingFile && currentFile) {
      const regex = new RegExp(
        filePrefixList.map(({ value }) => `-${value}-`).join(`|`),
        `g`,
      );

      await fetch(
        `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${currentFile.id}`,
        {
          method: `PATCH`,
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
          body: JSON.stringify({
            name: currentFile.name.replace(regex, `-${selectedFilePrefix}-`),
            file_type: type,
          }),
        },
      );

      onFileUploaded();
      setIsLoading(false);
      setModalOpen(false);
    }
  };

  return (
    <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
      <Dialog.Trigger asChild>
        {trigger ? (
          trigger
        ) : (
          <Button icon={isExisting ? `swap_horizontal_circle` : `add_circle`}>
            {isExisting ? `Update` : `Add`}
          </Button>
        )}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed bg-black/20 inset-0" />
        <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
          <div className="flex px-4 pt-4">
            <Dialog.Title className="flex grow text-lg">
              {isExisting ? `Update` : `Add`} {name}
            </Dialog.Title>
            <Dialog.Close tabIndex="-1">
              <i className="material-symbols-rounded text-xl">close</i>
            </Dialog.Close>
          </div>
          <div className="text-stone-700 text-sm px-4 pb-2">
            Upload the {name}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col p-4 gap-4">
              {Boolean(filePrefixList.length) && (
                <Select.Root
                  onValueChange={setSelectedFilePrefix}
                  value={selectedFilePrefix}
                  required
                  disabled={holdingFile}
                >
                  <Select.Trigger
                    aria-label="Select member"
                    className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300 flex justify-between items-center"
                  >
                    <Select.Value placeholder="Please select" />
                    <Select.Icon className="flex">
                      <i className="material-symbols-rounded text-xl text-stone-600 leading-none">
                        expand_more
                      </i>
                    </Select.Icon>
                  </Select.Trigger>
                  <Select.Portal className="z-10">
                    <Select.Content>
                      <Select.ScrollUpButton>
                        <i className="material-symbols-rounded text-xl text-stone-600">
                          expand_less
                        </i>
                      </Select.ScrollUpButton>
                      <Select.Viewport className="bg-white rounded-lg shadow-3xl border border-blue-50">
                        <Select.Item
                          value=""
                          className="p-2 text-sm flex items-center gap-2 cursor-pointer"
                        >
                          <Select.ItemText>Please select</Select.ItemText>
                        </Select.Item>
                        {filePrefixList.map(({ name, value }) => (
                          <Select.Item
                            key={name}
                            value={value}
                            className="p-2 text-sm flex items-center gap-2 cursor-pointer"
                          >
                            <Select.ItemText>{name}</Select.ItemText>
                            <Select.ItemIndicator className="flex">
                              <i className="material-symbols-rounded text-sm text-stone-600">
                                done
                              </i>
                            </Select.ItemIndicator>
                          </Select.Item>
                        ))}
                      </Select.Viewport>
                      <Select.ScrollDownButton>
                        <i className="material-symbols-rounded text-xl text-stone-600">
                          expand_more
                        </i>
                      </Select.ScrollDownButton>
                    </Select.Content>
                  </Select.Portal>
                </Select.Root>
              )}
              <div
                className={`relative items-center flex justify-center p-3 rounded-lg text-sm border border-stone-300 border-dashed hover:bg-stone-100 ${
                  isDragging ? `bg-stone-100` : `bg-stone-50`
                }`}
              >
                <div>
                  {holdingFile
                    ? holdingFile.name
                    : `Drag files or click here to upload`}
                </div>
                <input
                  type="file"
                  ref={file}
                  className="absolute inset-0 w-full opacity-0 cursor-pointer"
                  onChange={handleFileChange}
                  onDragEnter={() => setIsDragging(true)}
                  onDragLeave={() => setIsDragging(false)}
                  accept="application/pdf"
                />
              </div>
              <div className="flex justify-end py-4 text-stone-800">
                <Button
                  type="submit"
                  isDisabled={
                    !filePrefixList.length &&
                    (isLoading || isUploadLoading || disabled || !holdingFile)
                  }
                  isLoading={isLoading || isUploadLoading || disabled}
                >
                  Submit
                </Button>
              </div>
              {percent > 1 && file.current.files[0]?.name && (
                <div>
                  <div className="flex gap-8">
                    <div className="flex flex-col gap-2 flex-grow">
                      <div className="flex gap-2">
                        <div className="flex-grow">
                          {file.current.files[0]?.name}
                        </div>
                        <div>{percent}%</div>
                      </div>
                      <div className="h-1 bg-blue-50">
                        <div
                          className="h-1 bg-blue-700"
                          style={{ width: `${percent}%` }}
                        />
                      </div>
                    </div>
                    <button
                      className="flex items-center gap-2"
                      onClick={handleUploadCancel}
                    >
                      <i className="material-symbols-rounded text-2xl">close</i>
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
