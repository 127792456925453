import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import * as RadioGroup from "@radix-ui/react-radio-group";
import * as Select from "@radix-ui/react-select";
import * as Checkbox from "@radix-ui/react-checkbox";
import format from "date-fns/format";
import { v4 as uuid } from "uuid";
import { useAuth0 } from "@auth0/auth0-react";

import { Amend } from "../check-in/components";
import { uploadFile } from "../resources/utils";
import { Loading } from "../../components/loading/loading.js";
import { Button } from "../../components/button/button.js";
import { People } from "../../components/people";
import { Protected } from "../../components/protected";
import { Share } from "../../components/share";

const fetchProject = async ({
  setProject,
  id,
  setIsLoading = () => {},
  cache,
  getAccessTokenSilently,
}) => {
  if (cache.current?.project) {
    setProject(cache.current.project);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const project = await response.json();

    setProject({
      ...project,
      project_files: (project?.project_files || []).filter(
        ({ state }) => state !== `deleted`,
      ),
    });
    cache.current.project = project;
  } catch (_) {
    setIsLoading(false);
    return;
  }

  setIsLoading(false);
};

const fetchCheckIns = async ({
  setCheckIns,
  id,
  setIsLoading = () => {},
  getAccessTokenSilently,
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/checkins`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const { checkins } = await response.json();

    setCheckIns(checkins || []);
  } catch (_) {
    setIsLoading(false);
    return;
  }

  setIsLoading(false);
};

const Row = ({
  id,
  deliverable,
  short_description,
  commencement_date,
  projectId,
  checkin_type,
  primary_resource_id,
  refetch,
}) => {
  return (
    <tr className="border-b border-solid border-blue-50">
      <td className="px-6 py-3 min-w-[100%] max-w-[11rem] flex gap-2 flex-col">
        <Link className="hover:underline" to={`/check-ins/${projectId}/${id}`}>
          {deliverable}
        </Link>
        <div className="text-xs">{short_description}</div>
      </td>
      <td className="px-6 py-3">
        {format(new Date(commencement_date), `d LLL uuu`)}
      </td>
      <td className="px-6 py-3 text-right">
        <Protected requiredRoles={[`Pentatonic`]}>
          <Amend
            id={id}
            deliverable={deliverable}
            short_description={short_description}
            commencement_date={commencement_date}
            projectId={projectId}
            checkin_type={checkin_type}
            primary_resource_id={primary_resource_id}
            refetch={refetch}
          />
        </Protected>
      </td>
    </tr>
  );
};

const addCheckIn = async ({ fields, id, getAccessTokenSilently }) => {
  try {
    let folderId;

    if (fields.resources === `create`) {
      const file = new File([` `], `${uuid()}.__keep`, { type: "text/plain" });

      folderId = await uploadFile({
        id,
        file,
        path: `/${encodeURIComponent(fields.folder)}`,
        getAccessTokenSilently,
      });
    }

    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/checkins`,
      {
        method: `POST`,
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        body: JSON.stringify({
          ...fields,
          ...(folderId && { resources_folder_id: folderId }),
        }),
      },
    );

    if (!response.ok) {
      throw new Error();
    }
  } catch (error) {
    console.error(error);
    alert(`Failed to upload the file. Please try again.`);

    return false;
  }

  return true;
};

export const CheckIns = ({ setViewData, cache, session }) => {
  const defaultFields = {
    deliverable: ``,
    short_description: ``,
    commencement_date: ``,
    resources_folder_id: ``,
    folder: ``,
    resources: `create`,
    checkin_type: `regular`,
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [fields, setFields] = useState(defaultFields);
  const [project, setProject] = useState({});
  const [checkIns, setCheckIns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const handleChange = (name, value) => {
    setFields((previousFields) => ({ ...previousFields, [name]: value }));
  };
  const { getAccessTokenSilently } = useAuth0();
  const handleAdd = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const added = await addCheckIn({ fields, id, getAccessTokenSilently });
    if (added) {
      await fetchCheckIns({
        setCheckIns,
        id,
        setIsLoading,
        getAccessTokenSilently,
      });
      setFields(defaultFields);
      setIsLoading(false);
      setModalOpen(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProject({
      setProject,
      id,
      cache,
      setIsLoading,
      getAccessTokenSilently,
    });
    fetchCheckIns({ setCheckIns, id, setIsLoading, getAccessTokenSilently });
  }, [setProject, id, cache, getAccessTokenSilently]);

  useEffect(() => {
    setViewData({ title: project.title, id });
  }, [setViewData, project, id]);

  return (
    <>
      <div className="flex p-6 flex-col gap-5">
        <ul className="flex gap-2 items-center text-sm text-stone-500">
          <li className="flex gap-1 items-center text-stone-800">
            <Link to="/" className="hover:underline">
              Projects
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link
              to={`/projects/${id}?orgName=${encodeURIComponent(
                session.orgNiceName,
              )}`}
              className="hover:underline"
            >
              {project.title}
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link to={`/check-ins/${id}`} className="hover:underline">
              Check-Ins
            </Link>
          </li>
        </ul>
        <div className="flex grow items-center gap-3">
          <h1 className="text-3xl font-medium">Check-Ins</h1>
          {isLoading && <Loading large />}
          <div className="ml-auto flex items-center gap-5">
            <People projectId={id} orgId={session.orgId} />
            <Share />
            <div className="flex items-center gap-6">
              <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
                <Protected requiredRoles={[`Pentatonic`]}>
                  <Dialog.Trigger asChild>
                    <Button icon="strategy">Add Check-In</Button>
                  </Dialog.Trigger>
                </Protected>
                <Dialog.Portal>
                  <Dialog.Overlay className="fixed bg-black/20 inset-0" />
                  <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
                    <div className="flex border-b border-solid border-blue-50 p-4 items-start">
                      <div className="flex grow flex-col">
                        <Dialog.Title className="text-lg">
                          Add Check-In
                        </Dialog.Title>
                        <div className="text-stone-700 text-sm">
                          Add a milestone to the project
                        </div>
                      </div>
                      <Dialog.Close tabIndex="-1">
                        <i className="material-symbols-rounded text-xl">
                          close
                        </i>
                      </Dialog.Close>
                    </div>
                    <form onSubmit={handleAdd}>
                      <div className="flex flex-col p-4 gap-4">
                        <div className="flex flex-col gap-1">
                          <label
                            className="text-sm text-stone-800"
                            htmlFor="add_deliverable"
                          >
                            Deliverable
                          </label>
                          <input
                            type="text"
                            placeholder="Name of the check-in"
                            id="add_deliverable"
                            required
                            onChange={(event) =>
                              handleChange(`deliverable`, event.target.value)
                            }
                            value={fields.deliverable}
                            className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                            maxLength="256"
                            tabIndex="0"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label
                            className="text-sm text-stone-800"
                            htmlFor="add_short-description"
                          >
                            Short Description
                          </label>
                          <textarea
                            placeholder="Provide a reason for the check-in"
                            id="add_short-description"
                            required
                            onChange={(event) =>
                              handleChange(
                                `short_description`,
                                event.target.value,
                              )
                            }
                            value={fields.short_description}
                            className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                            maxLength="512"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label
                            className="text-sm text-stone-800"
                            htmlFor="add_commencementDate"
                          >
                            Commencement Date
                          </label>
                          <input
                            type="date"
                            placeholder="Select a date"
                            id="add_commencementDate"
                            required
                            onChange={(event) =>
                              handleChange(
                                `commencement_date`,
                                event.target.value,
                              )
                            }
                            value={fields.commencement_date}
                            className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                            maxLength="256"
                            tabIndex="0"
                          />
                        </div>
                        <div className="flex gap-3 items-center">
                          <Checkbox.Root
                            onCheckedChange={(value) =>
                              handleChange(
                                `checkin_type`,
                                value ? `pitch-back` : `regular`,
                              )
                            }
                            checked={fields.checkin_type === `pitch-back`}
                            id="add_pitch-back"
                            className="flex w-6 h-6 justify-center items-center cursor-pointer rounded-md border border-solid border-stone-300 after:font-2xl after:font-['Material_Symbols_Rounded'] after:content-['\e5ca'] after:block after:text-stone-100 aria-checked:after:text-blue-700"
                          />
                          <label
                            className="text-sm text-stone-800 cursor-pointer"
                            htmlFor="add_pitch-back"
                          >
                            Make this the Pitch Back
                          </label>
                        </div>
                        <div className="flex flex-col gap-1">
                          <label
                            className="text-sm text-stone-800"
                            htmlFor="add_resources_create"
                          >
                            Resources
                          </label>
                          <RadioGroup.Root
                            defaultValue="default"
                            aria-label="View density"
                            className="rounded-md border border-solid border-stone-300"
                            onValueChange={(value) =>
                              handleChange(`resources`, value)
                            }
                            value={fields.resources}
                            required
                          >
                            <div className="border-b border-solid border-stone-300 p-3 text-sm flex gap-3 items-center">
                              <RadioGroup.Item
                                value="create"
                                id="add_resources_create"
                                className="flex w-6 h-6 rounded-full border border-solid border-stone-300 after:content-[''] after:block after:rounded-full after:bg-stone-100 after:w-3 after:h-3 after:m-auto aria-checked:after:bg-blue-700"
                              >
                                <RadioGroup.Indicator />
                              </RadioGroup.Item>
                              <label
                                htmlFor="add_resources_create"
                                className="cursor-pointer"
                              >
                                Create a resource folder for this check-in
                              </label>
                            </div>
                            <div className="p-3 text-sm flex gap-3 items-center">
                              <RadioGroup.Item
                                value="existing"
                                id="add_resources_existing"
                                className="flex w-6 h-6 rounded-full border border-solid border-stone-300 after:content-[''] after:block after:rounded-full after:bg-stone-100 after:w-3 after:h-3 after:m-auto aria-checked:after:bg-blue-700"
                              >
                                <RadioGroup.Indicator />
                              </RadioGroup.Item>
                              <label
                                htmlFor="add_resources_existing"
                                className="cursor-pointer"
                              >
                                Select an existing folder
                              </label>
                            </div>
                          </RadioGroup.Root>
                        </div>
                        {fields.resources === `create` ? (
                          <div className="flex flex-col gap-1">
                            <label
                              className="text-sm text-stone-800"
                              htmlFor="add_folderName"
                            >
                              Folder name
                            </label>
                            <input
                              type="text"
                              placeholder="Check-in resource"
                              id="add_folderName"
                              required
                              onChange={(event) =>
                                handleChange(`folder`, event.target.value)
                              }
                              value={fields.folder}
                              className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                              maxLength="256"
                              tabIndex="0"
                            />
                          </div>
                        ) : (
                          <div className="flex flex-col gap-1">
                            <label
                              className="text-sm text-stone-800"
                              htmlFor="add_resource"
                            >
                              Link Resource
                            </label>
                            <Select.Root
                              onValueChange={(value) =>
                                handleChange(`resources_folder_id`, value)
                              }
                              value={fields.resources_folder_id}
                              required
                            >
                              <Select.Trigger
                                aria-label="Link Resource"
                                className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300 flex justify-between items-center"
                              >
                                <Select.Value placeholder="Please select" />
                                <Select.Icon className="flex">
                                  <i className="material-symbols-rounded text-xl text-stone-600 leading-none">
                                    expand_more
                                  </i>
                                </Select.Icon>
                              </Select.Trigger>
                              <Select.Portal>
                                <Select.Content>
                                  <Select.ScrollUpButton>
                                    <i className="material-symbols-rounded text-xl text-stone-600">
                                      expand_less
                                    </i>
                                  </Select.ScrollUpButton>
                                  <Select.Viewport className="bg-white rounded-lg shadow-3xl border border-blue-50">
                                    {(project?.project_files || [])
                                      .filter(
                                        ({ name, state, folder_path }) =>
                                          state !== `deleted` &&
                                          !folder_path.startsWith(
                                            `/ah7rhd-sow`,
                                          ) &&
                                          name.endsWith(`.__keep`) &&
                                          folder_path !== `/`,
                                      )
                                      .map(({ id: fileId, folder_path }) => (
                                        <Select.Item
                                          key={fileId}
                                          value={fileId}
                                          className="p-2 text-sm flex items-center gap-2 cursor-pointer"
                                        >
                                          <Select.ItemText>
                                            {folder_path
                                              .split(`/`)
                                              .filter(Boolean)
                                              .join(` - `)}
                                          </Select.ItemText>
                                          <Select.ItemIndicator className="flex">
                                            <i className="material-symbols-rounded text-sm text-stone-600">
                                              done
                                            </i>
                                          </Select.ItemIndicator>
                                        </Select.Item>
                                      ))}
                                  </Select.Viewport>
                                  <Select.ScrollDownButton>
                                    <i className="material-symbols-rounded text-xl text-stone-600">
                                      expand_more
                                    </i>
                                  </Select.ScrollDownButton>
                                </Select.Content>
                              </Select.Portal>
                            </Select.Root>
                          </div>
                        )}
                      </div>
                      <div className="flex justify-end border-t border-solid border-blue-50 p-4">
                        <Button
                          type="submit"
                          disabled={isLoading}
                          isLoading={isLoading}
                        >
                          Add Check-In
                        </Button>
                      </div>
                    </form>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </div>
          </div>
        </div>
      </div>
      <table className="table-auto border-spacing-x-6 border-spacing-y-4">
        <thead className="text-xs font-medium uppercase text-stone-800 border-t border-b border-solid border-blue-50">
          <tr>
            <th className="px-6 py-3 text-left uppercase">
              Deliverable &amp; Description
            </th>
            <th className="px-6 py-3 text-left uppercase" colSpan="2">
              Commencement Date
            </th>
          </tr>
        </thead>
        <tbody className="py-4 px-6 text-stone-700 text-sm font-normal">
          {checkIns.map(({ id: checkInId, ...rest }) => (
            <Row
              key={id}
              id={checkInId}
              {...rest}
              projectId={id}
              refetch={() =>
                fetchCheckIns({
                  setCheckIns,
                  id,
                  setIsLoading,
                  getAccessTokenSilently,
                })
              }
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
