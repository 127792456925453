import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";
import { useInView } from "react-intersection-observer";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useAuth0 } from "@auth0/auth0-react";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import { Loading } from "../loading/loading.js";
import { Button } from "../button/button.js";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const buildAccessToken = async ({ getAccessTokenSilently, setAccessToken }) =>
  setAccessToken(await getAccessTokenSilently());

const PdfPage = ({
  pageNumber,
  currentPage,
  onPageChange,
  isManuallyNavigating,
  onRenderSuccess,
  width,
}) => {
  const { ref, inView } = useInView({ threshold: 0.4, delay: 250 });

  useEffect(() => {
    if (!isManuallyNavigating && inView && pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  }, [currentPage, isManuallyNavigating, inView, onPageChange, pageNumber]);

  return (
    <div
      id={`page-${pageNumber}`}
      className="mx-auto pt-10 relative flex justify-center"
      ref={ref}
    >
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
      >
        {({ zoomIn, zoomOut }) => {
          return (
            <div
              className={`rounded-lg overflow-hidden max-w-fit mx-auto cursor-pointer hover:shadow-active ${
                pageNumber === currentPage ? `shadow-active` : `shadow-4xl`
              }`}
            >
              <div className="absolute top-14 right-24 z-10 flex gap-2">
                <button onClick={() => zoomOut()}>
                  <i className="material-symbols-rounded leading-none hover:text-blue-500">
                    zoom_out
                  </i>
                </button>
                <button onClick={() => zoomIn()}>
                  <i className="material-symbols-rounded leading-none hover:text-blue-500">
                    zoom_in
                  </i>
                </button>
              </div>
              <TransformComponent>
                <Page
                  pageNumber={pageNumber}
                  width={width - 224}
                  loading=""
                  onClick={() => onPageChange(pageNumber)}
                  onRenderSuccess={onRenderSuccess}
                />
              </TransformComponent>
            </div>
          );
        }}
      </TransformWrapper>
    </div>
  );
};

export const PdfViewer = ({ url }) => {
  const firstLoad = useRef(true);
  const [loadedPages, setLoadedPages] = useState([1]);
  const [isManuallyNavigating, setIsManuallyNavigating] = useState(true);
  const isManuallyNavigatingTimeout = useRef();
  const onPageChange = (page) => {
    setIsManuallyNavigating(true);
    window.location.hash = `#page-${page}`;

    clearTimeout(isManuallyNavigatingTimeout.current);
    isManuallyNavigatingTimeout.current = setTimeout(() => {
      setIsManuallyNavigating(false);
    }, 1000);
  };
  const { hash } = useLocation();
  const hasHash = hash.startsWith(`#page-`);
  const currentPage = hasHash ? parseInt(hash.replace(`#page-`, ``) || 1) : 1;
  const [space, setSpace] = useState({ width: 0, height: 0 });
  const [totalPages, setTotalPages] = useState();
  const [pageInput, setPageInput] = useState(currentPage);
  const wrapper = useRef();
  const onLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };
  const onRenderSuccess = (page) => {
    setLoadedPages((previousLoadedPages) =>
      previousLoadedPages.includes(page)
        ? previousLoadedPages
        : [...previousLoadedPages, page],
    );
  };
  const handle = useFullScreenHandle();
  const [previousHandleActive, setPreviousHandleActive] = useState(
    handle.active,
  );
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    buildAccessToken({ getAccessTokenSilently, setAccessToken });
  }, [getAccessTokenSilently]);

  const options = useMemo(
    () => ({
      httpHeaders: { Authorization: `Bearer ${accessToken}` },
      withCredentials: true,
    }),
    [accessToken],
  );

  useEffect(() => {
    if (firstLoad.current) {
      if (hasHash && loadedPages.includes(currentPage)) {
        firstLoad.current = false;
        const backedUpCurrentPage = currentPage;
        window.location.hash = ``;
        onPageChange(backedUpCurrentPage);
      }

      if (!hasHash && loadedPages.includes(1)) {
        firstLoad.current = false;
        onPageChange(1);
      }
    }
  }, [currentPage, hasHash, loadedPages]);

  useEffect(() => {
    if (accessToken) {
      setSpace({
        width: wrapper.current?.offsetWidth,
        height:
          window.innerHeight -
          (wrapper.current?.getBoundingClientRect().top || 0),
      });
    }
  }, [accessToken, wrapper]);

  useEffect(() => {
    if (previousHandleActive !== handle.active) {
      setPreviousHandleActive(handle.active);
      setSpace({
        width: 0,
        height: 0,
      });
      setTimeout(() => {
        const backedUpCurrentPage = currentPage;
        window.location.hash = ``;
        setSpace({
          width: wrapper.current?.offsetWidth,
          height:
            window.innerHeight -
            (wrapper.current?.getBoundingClientRect().top || 0),
        });
        onPageChange(backedUpCurrentPage);
      }, 100);
    }
  }, [currentPage, handle.active, previousHandleActive]);

  useEffect(() => {
    setPageInput(currentPage);
  }, [currentPage]);

  if (!accessToken) {
    return null;
  }

  return (
    <FullScreen handle={handle} className="bg-white">
      <div className="flex items-center justify-center text-stone-800 gap-3 border-t border-b border-solid border-blue-50 py-4">
        <div className="flex align-center font-medium">Current page</div>
        <input
          type="number"
          value={pageInput}
          onChange={(event) => setPageInput(event.target.value)}
          onBlur={(event) =>
            event.target.value && onPageChange(event.target.value)
          }
          onKeyUp={(event) =>
            event.target.value &&
            event.key === `Enter` &&
            onPageChange(event.target.value)
          }
          className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-stone-800 placeholder:text-stone-300 max-w-[2.75rem] text-center"
        />
        <div>/</div>
        <div>{totalPages ? totalPages : <Loading />}</div>
        <button
          onClick={() => onPageChange(parseInt(currentPage || 1) - 1)}
          disabled={currentPage === 1}
          className="flex text-stone-600 text-lg"
        >
          <i className="material-symbols-rounded leading-none hover:text-blue-500">
            chevron_left
          </i>
        </button>
        <button
          onClick={() => onPageChange(parseInt(currentPage || 1) + 1)}
          disabled={currentPage === totalPages}
          className="flex text-stone-600 text-lg"
        >
          <i className="material-symbols-rounded leading-none hover:text-blue-500">
            chevron_right
          </i>
        </button>
        {handle.active ? (
          <button onClick={handle.exit} className="flex">
            <i className="material-symbols-rounded leading-none hover:text-blue-500">
              fullscreen_exit
            </i>
          </button>
        ) : (
          <button onClick={handle.enter} className="flex">
            <i className="material-symbols-rounded leading-none hover:text-blue-500">
              fullscreen
            </i>
          </button>
        )}
      </div>
      <div
        ref={wrapper}
        className={`pb-10 relative ${
          loadedPages.length ? `overflow-auto` : `overflow-hidden`
        }`}
        style={{ height: `${space.height}px` }}
      >
        <Document
          file={url}
          options={options}
          onLoadSuccess={onLoadSuccess}
          className="px-10"
          loading=""
          error={
            <div className="flex gap-2 justify-center items-center p-4">
              <div>Failed to load document.</div>
              <Button
                onClick={() => {
                  window.location.assign(window.location.href.split(`#`)[0]);
                }}
              >
                Retry
              </Button>
            </div>
          }
        >
          {[
            ...loadedPages,
            totalPages &&
              totalPages !== loadedPages.length &&
              loadedPages[loadedPages.length - 1] + 1,
          ]
            .filter((page) => page !== undefined)
            .map((page) => (
              <PdfPage
                onRenderSuccess={() => onRenderSuccess(page)}
                key={`page_${page}`}
                pageNumber={page}
                currentPage={currentPage}
                onPageChange={onPageChange}
                isManuallyNavigating={isManuallyNavigating}
                width={space.width}
              />
            ))}
        </Document>
      </div>
    </FullScreen>
  );
};
