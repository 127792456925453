import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import * as Toast from "@radix-ui/react-toast";
import { useAuth0 } from "@auth0/auth0-react";

import { Button } from "../../components/button/button.js";
import { Loading } from "../../components/loading/loading.js";
import { Protected } from "../../components/protected/protected.js";
import { Project } from "./components";

const fetchProjects = async ({
  setProjects,
  setIsLoading = () => {},
  getAccessTokenSilently,
}) => {
  let projects;
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error(`Projects: Response not OK`);
    }

    ({ projects } = await response.json());
  } catch (_) {
    projects = [];
  }

  setProjects(projects);
  setIsLoading(false);
};

const updateProject = async ({
  fields,
  session,
  id,
  getAccessTokenSilently,
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects${
        id ? `/${id}` : ``
      }`,
      {
        method: id ? `PATCH` : `POST`,
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        body: JSON.stringify({
          ...fields,
          org_id: session.orgId,
        }),
      },
    );

    if (!response.ok) {
      throw new Error();
    }
  } catch (_) {
    return false;
  }

  return true;
};

export const Home = ({ session }) => {
  const defaultFields = { title: ``, short_description: `` };
  const [isLoading, setIsLoading] = useState(true);
  const [toastOpen, setToastOpen] = useState({ open: false });
  const [modalOpen, setModalOpen] = useState(false);
  const [isAmending, setIsAmending] = useState();
  const [projects, setProjects] = useState([]);
  const [fields, setFields] = useState(defaultFields);
  const handleChange = (name, value) => {
    setFields((previousFields) => ({ ...previousFields, [name]: value }));
  };
  const { getAccessTokenSilently } = useAuth0();
  const handleUpdateProject = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const updated = await updateProject({
      fields,
      session,
      id: isAmending,
      getAccessTokenSilently,
    });
    if (updated) {
      await fetchProjects({ setProjects, getAccessTokenSilently });
      setIsLoading(false);
      setModalOpen(false);
      setFields(defaultFields);
      setIsAmending();
      setToastOpen({
        open: true,
        type: `success`,
        title: `Project ${isAmending ? `Amended` : `Added`}`,
        message: `The project ${fields.title} was ${
          isAmending ? `amended` : `added`
        } successfully.`,
      });
    } else {
      setIsLoading(false);
      setToastOpen({
        open: true,
        type: `error`,
        title: `Project ${isAmending ? `amend` : `add`} Failed`,
        message: `There was an error when trying ${
          isAmending ? `amend` : `add`
        } the project. Try again.`,
      });
    }
  };
  const handleAmendClick = ({ id, title, short_description }) => {
    setFields({ title, short_description });
    setIsAmending(id);
    setModalOpen(true);
  };

  useEffect(() => {
    fetchProjects({ setProjects, setIsLoading, getAccessTokenSilently });
  }, [getAccessTokenSilently, setProjects]);

  return (
    <>
      <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
        <div className="flex p-6">
          <div className="flex grow items-center gap-3">
            <h1 className="text-3xl font-medium">Projects</h1>
            {isLoading && <Loading large />}
          </div>
          <Protected requiredRoles={[`Pentatonic`]}>
            <Dialog.Trigger asChild>
              <Button icon="add_circle">Create Project</Button>
            </Dialog.Trigger>
          </Protected>
        </div>
        <table className="table-auto border-spacing-x-6 border-spacing-y-4">
          <thead className="text-xs font-medium uppercase text-stone-800 border-t border-b border-solid border-blue-50">
            <tr>
              <th className="px-6 py-3 text-left">Project</th>
              <th className="px-6 py-3 text-left">Updated</th>
              <th className="px-6 py-3 text-left">Members</th>
              <th className="px-6 py-3 text-left" colSpan="2">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="py-4 px-6 text-stone-700 text-sm font-normal">
            {projects.map((project) => (
              <Project
                key={project.id}
                id={project.id}
                refetch={() =>
                  fetchProjects({ setProjects, getAccessTokenSilently })
                }
                session={session}
                handleAmendClick={handleAmendClick}
                {...project}
              />
            ))}
          </tbody>
        </table>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed bg-black/20 inset-0" />
          <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
            <div className="flex border-b border-solid border-blue-50 p-4">
              <Dialog.Title className="flex grow text-lg">
                {isAmending ? `Amend` : `Create`} Project
              </Dialog.Title>
              <Dialog.Close tabIndex="-1">
                <i className="material-symbols-rounded text-xl">close</i>
              </Dialog.Close>
            </div>
            <form onSubmit={handleUpdateProject}>
              <div className="flex flex-col p-4 gap-4">
                <div className="flex flex-col gap-1">
                  <label
                    className="text-sm text-stone-800"
                    htmlFor="add-project_name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="Name of the project"
                    id="add-project_name"
                    required
                    onChange={(event) =>
                      handleChange(`title`, event.target.value)
                    }
                    value={fields.title}
                    className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                    maxLength="256"
                    tabIndex="0"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    className="text-sm text-stone-800"
                    htmlFor="add-project_description"
                  >
                    Short Description
                  </label>
                  <textarea
                    placeholder="Describe the project in a sentence..."
                    id="add-project_description"
                    required
                    onChange={(event) =>
                      handleChange(`short_description`, event.target.value)
                    }
                    value={fields.short_description}
                    className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                    maxLength="512"
                  />
                </div>
              </div>
              <div className="flex justify-end border-t border-solid border-blue-50 p-4">
                <Button
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  {isAmending ? `Update` : `Add`} project
                </Button>
              </div>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <Toast.Root
        open={toastOpen.open}
        onOpenChange={(open) => setToastOpen({ ...toastOpen, open })}
        className="fixed bottom-0 right-0 p-4 z-10"
      >
        <div className="rounded-lg shadow-4xl bg-white p-2 flex gap-3 max-w-[20rem] items-start border border-blue-50">
          <i
            className={`material-symbols-rounded text-lg leading-none ${
              toastOpen.type === `error` ? `text-[#c22960]` : `text-[#00cfa8]`
            }`}
          >
            {toastOpen.type === `error` ? `error` : `done`}
          </i>
          <div className="flex flex-col gap-1">
            <Toast.Title className="font-medium text-sm text-stone-800">
              {toastOpen.title}
            </Toast.Title>
            <Toast.Description className="text-xs text-stone-500 pb-1">
              {toastOpen.message}
            </Toast.Description>
          </div>
          <Toast.Close>
            <i className="material-symbols-rounded text-lg leading-none text-stone-300 hover:text-stone-600">
              close
            </i>
          </Toast.Close>
        </div>
      </Toast.Root>
    </>
  );
};

export const HomeNavigation = () => {
  return (
    <ul className="leading-tight text-sm font-medium flex flex-col gap-2">
      <li>
        <Link
          className="p-2 rounded w-full flex items-center gap-3 hover:bg-blue-50 hover:text-blue-500 group bg-blue-50 text-blue-500"
          to="/"
        >
          <i className="material-symbols-rounded text-xl text-blue-500 group-hover:text-blue-500">
            rocket_launch
          </i>{" "}
          Projects
        </Link>
      </li>
      <li>
        <Link
          className="p-2 rounded w-full flex items-center gap-3 hover:bg-blue-50 hover:text-blue-500 group"
          to="/"
        >
          <i className="material-symbols-rounded text-xl text-stone-500 group-hover:text-blue-500">
            build_circle
          </i>{" "}
          Decommissioning
        </Link>
      </li>
      <li>
        <Link
          className="p-2 rounded w-full flex items-center gap-3 hover:bg-blue-50 hover:text-blue-500 group"
          to="/"
        >
          <i className="material-symbols-rounded text-xl text-stone-500 group-hover:text-blue-500">
            box
          </i>{" "}
          Take-back Program
        </Link>
      </li>
      <li>
        <Link
          className="p-2 rounded w-full flex items-center gap-3 hover:bg-blue-50 hover:text-blue-500 group"
          to="/"
        >
          <i className="material-symbols-rounded text-xl text-stone-500 group-hover:text-blue-500">
            unknown_document
          </i>{" "}
          Knowledge Base
        </Link>
      </li>
    </ul>
  );
};
