import { useEffect, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import * as Dialog from "@radix-ui/react-dialog";
import * as Checkbox from "@radix-ui/react-checkbox";
import { Link, useNavigate } from "react-router-dom";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { useAuth0 } from "@auth0/auth0-react";

import { Button } from "../../../../components/button/button.js";

export const Amend = ({
  deliverable,
  short_description,
  commencement_date,
  projectId,
  id,
  checkin_type,
  primary_resource_id,
  refetch = async () => {},
  isViewing,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [fields, setFields] = useState({
    deliverable,
    short_description,
    commencement_date,
    checkin_type,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState();
  const handleChange = (name, value) => {
    setFields((previousFields) => ({ ...previousFields, [name]: value }));
  };
  const handleAmend = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      await fetch(
        `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/checkins/${id}`,
        {
          method: `PATCH`,
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
          body: JSON.stringify({
            ...fields,
          }),
        },
      );
    } catch (_) {
      setIsLoading(false);
      return;
    }

    await refetch();

    setIsLoading(false);
    setModalOpen(false);
  };
  const handleUnlink = async () => {
    await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/checkins/${id}/primary-resource`,
      {
        method: `DELETE`,
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );

    refetch();
  };
  const handleArchiveClick = async () => {
    setIsDeleting(true);
    await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/checkins/${id}`,
      {
        method: `DELETE`,
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    if (isViewing) {
      return navigate(`/check-ins/${projectId}`);
    }
    await refetch();
    setIsDeleting(false);
  };

  useEffect(() => {
    setFields({
      deliverable,
      short_description,
      commencement_date,
      checkin_type,
    });
  }, [deliverable, short_description, commencement_date, checkin_type]);

  return (
    <div>
      <Popover.Root>
        <Popover.Trigger>
          <i className="material-symbols-rounded text-xl text-stone-600">
            more_horiz
          </i>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="rounded-lg shadow-3xl p-2 bg-white min-w-[12rem]"
            collisionPadding={16}
            sideOffset={8}
          >
            <ul>
              {!isViewing && (
                <li>
                  <Link
                    to={`/check-ins/${projectId}/${id}`}
                    className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium hover:underline"
                  >
                    View
                  </Link>
                </li>
              )}
              <li>
                <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
                  <Dialog.Trigger asChild>
                    <button className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium">
                      Amend
                    </button>
                  </Dialog.Trigger>
                  <Dialog.Portal>
                    <Dialog.Overlay className="fixed bg-black/20 inset-0" />
                    <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
                      <div className="flex border-b border-solid border-blue-50 p-4 items-start">
                        <div className="flex grow flex-col">
                          <Dialog.Title className="text-lg">
                            Amend Check-In
                          </Dialog.Title>
                          <div className="text-stone-700 text-sm">
                            Amend a milestone for this project
                          </div>
                        </div>
                        <Dialog.Close tabIndex="-1">
                          <i className="material-symbols-rounded text-xl">
                            close
                          </i>
                        </Dialog.Close>
                      </div>
                      <form onSubmit={handleAmend}>
                        <div className="flex flex-col p-4 gap-4">
                          <div className="flex flex-col gap-1">
                            <label
                              className="text-sm text-stone-800"
                              htmlFor="add_deliverable"
                            >
                              Deliverable
                            </label>
                            <input
                              type="text"
                              placeholder="Name of the check-in"
                              id="add_deliverable"
                              required
                              onChange={(event) =>
                                handleChange(`deliverable`, event.target.value)
                              }
                              value={fields.deliverable}
                              className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                              maxLength="256"
                              tabIndex="0"
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <label
                              className="text-sm text-stone-800"
                              htmlFor="add_short-description"
                            >
                              Short Description
                            </label>
                            <textarea
                              placeholder="Provide a reason for the check-in"
                              id="add_short-description"
                              required
                              onChange={(event) =>
                                handleChange(
                                  `short_description`,
                                  event.target.value,
                                )
                              }
                              value={fields.short_description}
                              className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                              maxLength="512"
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <label
                              className="text-sm text-stone-800"
                              htmlFor="add_commencementDate"
                            >
                              Commencement Date
                            </label>
                            <input
                              type="date"
                              placeholder="Select a date"
                              id="add_commencementDate"
                              required
                              onChange={(event) =>
                                handleChange(
                                  `commencement_date`,
                                  event.target.value,
                                )
                              }
                              value={fields.commencement_date}
                              className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                              maxLength="256"
                              tabIndex="0"
                            />
                          </div>
                          <div className="flex gap-3 items-center">
                            <Checkbox.Root
                              onCheckedChange={(value) =>
                                handleChange(
                                  `checkin_type`,
                                  value ? `pitch-back` : `regular`,
                                )
                              }
                              checked={fields.checkin_type === `pitch-back`}
                              id="add_pitch-back"
                              className="flex w-6 h-6 justify-center items-center cursor-pointer rounded-md border border-solid border-stone-300 after:font-2xl after:font-['Material_Symbols_Rounded'] after:content-['\e5ca'] after:block after:text-stone-100 aria-checked:after:text-blue-700"
                            />
                            <label
                              className="text-sm text-stone-800 cursor-pointer"
                              htmlFor="add_pitch-back"
                            >
                              Make this the Pitch Back
                            </label>
                          </div>
                        </div>
                        <div className="flex justify-end border-t border-solid border-blue-50 p-4">
                          <Button
                            type="submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                          >
                            Amend Check-In
                          </Button>
                        </div>
                      </form>
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>
              </li>
              {primary_resource_id && (
                <li>
                  <button
                    className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium"
                    onClick={handleUnlink}
                  >
                    Unlink Primary Resource
                  </button>
                </li>
              )}
              <li>
                <AlertDialog.Root>
                  <AlertDialog.Trigger className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium">
                    Archive
                  </AlertDialog.Trigger>
                  <AlertDialog.Portal>
                    <AlertDialog.Overlay className="fixed bg-black/20 inset-0" />
                    <AlertDialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
                      <AlertDialog.Title className="flex border-b border-solid border-blue-50 p-4">
                        Archive
                      </AlertDialog.Title>
                      <AlertDialog.Description className="p-4 text-sm text-stone-800">
                        Are you sure you want to archive this?
                      </AlertDialog.Description>
                      <div className="flex justify-end border-t border-solid border-blue-50 p-4 gap-4">
                        <Popover.Close>
                          <AlertDialog.Cancel asChild>
                            <Button
                              disabled={isDeleting}
                              isLoading={isDeleting}
                            >
                              Cancel
                            </Button>
                          </AlertDialog.Cancel>
                        </Popover.Close>
                        <Button
                          onClick={handleArchiveClick}
                          disabled={isDeleting}
                          isLoading={isDeleting}
                        >
                          Proceed
                        </Button>
                      </div>
                    </AlertDialog.Content>
                  </AlertDialog.Portal>
                </AlertDialog.Root>
              </li>
            </ul>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};
