export const uploadFile = async ({
  id,
  file,
  path,
  name,
  setPercent = () => {},
  cancelFileUpload,
  type,
  getAccessTokenSilently,
}) => {
  const token = await getAccessTokenSilently();
  const fileType = type || `regular`;
  setPercent(2);

  const uploadUrlResponse = await fetch(
    `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files`,
    {
      method: "POST",
      headers: {
        "Content-Type": `application/json`,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name || file.name,
        folder_path: path,
        file_size: file.size,
        is_root: file.name.endsWith(`.__keep`),
        file_type: `regular`,
      }),
    },
  );

  if (uploadUrlResponse.status === 409) {
    setPercent(0);

    alert(`File with the name '${file.name}' already exists in this project.`);

    return;
  }

  setPercent(3);

  const {
    upload_url: uploadUrl,
    data,
    id: fileId,
  } = await uploadUrlResponse.json();

  setPercent(4);

  const formdata = new FormData();

  formdata.append(`key`, data.key);
  formdata.append(`AWSAccessKeyId`, data.AWSAccessKeyId);
  formdata.append(`x-amz-security-token`, data[`x-amz-security-token`]);
  formdata.append(`policy`, data.policy);
  formdata.append(`signature`, data.signature);
  formdata.append(`file`, file);

  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
      const percent = Math.round((100 * event.loaded) / event.total);
      if (percent > 4 && percent < 100) setPercent(percent);
    };
    xhr.upload.onerror = async () => {
      await fetch(
        `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${fileId}/lifecycle`,
        {
          method: `PUT`,
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ state: `deleted` }),
        },
      );
      reject();
    };
    xhr.onload = async () => {
      if (fileType !== `regular`) {
        await fetch(
          `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${fileId}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": `application/json`,
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              file_type: fileType,
            }),
          },
        );
      }
      resolve(fileId);
    };

    if (cancelFileUpload) {
      cancelFileUpload.current = () => xhr.abort();
    }

    xhr.open(`POST`, uploadUrl);
    xhr.send(formdata);
  });
};
