import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { formatDistance } from "date-fns";
import * as Popover from "@radix-ui/react-popover";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import * as Dialog from "@radix-ui/react-dialog";
import { useAuth0 } from "@auth0/auth0-react";

import { Loading } from "../../components/loading/loading.js";
import { Button } from "../../components/button/button.js";
import { People } from "../../components/people";
import { Protected } from "../../components/protected";
import { Share } from "../../components/share";

import { ManageDirectory } from "./components";

import { uploadFile } from "./utils";

const fetchProject = async ({
  setProject,
  id,
  setIsLoading,
  /* cache, */
  folderPath,
  getAccessTokenSilently,
}) => {
  // if (cache.current?.project) {
  //   setProject(cache.current.project);
  // }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const project = await response.json();
    setProject({
      ...project,
      allFiles: (project?.project_files || [])
        .filter(
          ({ folder_path, state }) =>
            state !== `deleted` && !folder_path.startsWith(`/ah7rhd-sow`),
        )
        .map(({ name, ...rest }) => ({
          ...rest,
          name,
          isDirectory: name.endsWith(`.__keep`),
        })),
      project_files: (project?.project_files || [])
        .filter(({ folder_path, name, state }) => {
          return (
            state !== `deleted` &&
            !folder_path.startsWith(`/ah7rhd-sow`) &&
            (folder_path === folderPath ||
              (folder_path.startsWith(folderPath) &&
                folderPath.split(`/`).length ===
                  folder_path.split(`/`).length) ||
              (folderPath === `/` &&
                name.endsWith(`.__keep`) &&
                folder_path.split(`/`).length === 2))
          );
        })
        .map(({ name, ...rest }) => ({
          ...rest,
          name,
          isDirectory: name.endsWith(`.__keep`),
        })),
    });
    // cache.current.project = project;
  } catch (_) {
    setIsLoading(false);
    return;
  }

  setIsLoading(false);
};

const sizeOfDirectoryFiles = ({ path, files }) => {
  return files
    .filter(({ folder_path }) => folder_path.startsWith(path))
    .reduce((accumulator, { file_size }) => accumulator + file_size, 0);
};

const Row = ({
  id,
  fileId,
  name,
  date_uploaded,
  setProject,
  uploaded_by_avatar,
  uploaded_by_name,
  file_size,
  setIsLoading,
  // cache,
  isDirectory,
  folder_path,
  folderPath,
  allFiles,
  checkin_link,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const defaultFields = {
    name: isDirectory ? folder_path.split(`/`).pop() : name.split(`.`).shift(),
  };
  const [fields, setFields] = useState(defaultFields);
  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const handleChange = (name, value) => {
    setFields((previousFields) => ({ ...previousFields, [name]: value }));
  };
  const handleTrashClick = async () => {
    setIsDeleting(true);

    try {
      await fetch(
        `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${fileId}/lifecycle`,
        {
          method: `PUT`,
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
          body: JSON.stringify({ state: `deleted` }),
        },
      );

      await fetchProject({
        setProject,
        id,
        setIsLoading,
        /*cache, */
        folderPath,
        getAccessTokenSilently,
      });
    } catch (_) {
      setIsDeleting(false);
      return;
    }

    setIsDeleting(false);
  };
  const handleRename = async (event) => {
    event.preventDefault();

    setIsRenaming(true);

    try {
      if (isDirectory) {
        const filesToRename = allFiles.filter(
          (file) =>
            file.folder_path !== folder_path &&
            file.folder_path.includes(folder_path),
        );

        await Promise.all(
          filesToRename.map(async (file) => {
            return fetch(
              `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${file.id}`,
              {
                method: `PATCH`,
                headers: {
                  "Content-Type": `application/json`,
                  Authorization: `Bearer ${await getAccessTokenSilently()}`,
                },
                body: JSON.stringify({
                  folder_path: file.folder_path.replace(
                    `/${folder_path.split(`/`).pop()}`,
                    `/${fields.name}`,
                  ),
                  name: file.name,
                }),
              },
            );
          }),
        );
      }

      const newFileSettings = isDirectory
        ? {
            folder_path: folder_path.replace(
              `/${folder_path.split(`/`).pop()}`,
              `/${fields.name}`,
            ),
            name,
          }
        : {
            folder_path,
            name: name.replace(
              `${name.split(`.`).shift()}.`,
              `${fields.name}.`,
            ),
          };

      await fetch(
        `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${fileId}`,
        {
          method: `PATCH`,
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
          body: JSON.stringify(newFileSettings),
        },
      );

      await fetchProject({
        setProject,
        id,
        setIsLoading,
        /*cache, */
        folderPath,
        getAccessTokenSilently,
      });

      setModalOpen(false);
    } catch (_) {
      setIsRenaming(false);
      return;
    }

    setIsRenaming(false);
  };

  return (
    <tr className="border-b border-solid border-blue-50">
      <td className="px-6 py-3">
        <div className="flex items-center gap-3">
          {checkin_link && (
            <i className="material-symbols-rounded text-xl leading-none">
              link
            </i>
          )}
          <Link
            to={
              isDirectory
                ? `/resources/${id}${folder_path}`
                : `/api/projects/${id}/files/${fileId}`
            }
            className="hover:underline"
            reloadDocument={!isDirectory}
          >
            {isDirectory ? (
              <div className="flex items-center gap-3">
                <i className="material-symbols-rounded text-xl leading-none">
                  folder
                </i>
                {decodeURIComponent(folder_path.split(`/`).pop())}
              </div>
            ) : (
              decodeURIComponent(name)
            )}
          </Link>
        </div>
      </td>
      <td className="px-6 py-3">
        <div className="flex gap-2 items-center">
          <img
            src={uploaded_by_avatar}
            alt={uploaded_by_name}
            className="w-6 h-6 rounded-full"
          />
          {uploaded_by_name}
        </div>
      </td>
      <td className="px-6 py-3">
        {formatDistance(new Date(date_uploaded), new Date(), {
          addSuffix: true,
        })}
      </td>
      <td className="px-6 py-3">
        {(
          (isDirectory
            ? sizeOfDirectoryFiles({ path: folder_path, files: allFiles })
            : file_size) / 1000000
        ).toFixed(2)}
        {` `}
        MB
      </td>
      <td className="px-6 py-3 text-right">
        <Protected requiredRoles={[`Pentatonic`]}>
          <Popover.Root>
            <Popover.Trigger>
              <i className="material-symbols-rounded text-xl text-stone-600">
                more_horiz
              </i>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                className="rounded-lg shadow-3xl p-2 bg-white min-w-[12rem]"
                collisionPadding={16}
                sideOffset={8}
              >
                <ul>
                  <li>
                    <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
                      <Dialog.Trigger className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium">
                        Rename
                      </Dialog.Trigger>
                      <Dialog.Portal>
                        <Dialog.Overlay className="fixed bg-black/20 inset-0" />
                        <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
                          <div className="flex border-b border-solid border-blue-50 p-4">
                            <Dialog.Title className="flex grow text-lg">
                              Rename
                            </Dialog.Title>
                            <Dialog.Close tabIndex="-1">
                              <i className="material-symbols-rounded text-xl">
                                close
                              </i>
                            </Dialog.Close>
                          </div>
                          <form onSubmit={handleRename}>
                            <div className="flex flex-col p-4 gap-4">
                              <div className="flex flex-col gap-1">
                                <label
                                  className="text-sm text-stone-800"
                                  htmlFor="rename_name"
                                >
                                  New name
                                </label>
                                <input
                                  type="text"
                                  id="rename_name"
                                  required
                                  onChange={(event) =>
                                    handleChange(
                                      `name`,
                                      encodeURIComponent(event.target.value),
                                    )
                                  }
                                  value={decodeURIComponent(fields.name)}
                                  className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                                  maxLength="256"
                                  tabIndex="0"
                                />
                              </div>
                            </div>
                            <div className="flex justify-end border-t border-solid border-blue-50 p-4">
                              <Button
                                type="submit"
                                disabled={isRenaming}
                                isLoading={isRenaming}
                              >
                                Apply
                              </Button>
                            </div>
                          </form>
                        </Dialog.Content>
                      </Dialog.Portal>
                    </Dialog.Root>
                  </li>
                  {!isDirectory && (
                    <li>
                      <a
                        className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium hover:underline"
                        href={`${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${fileId}`}
                      >
                        Download
                      </a>
                    </li>
                  )}
                  <li>
                    <AlertDialog.Root>
                      <AlertDialog.Trigger className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium">
                        Delete
                      </AlertDialog.Trigger>
                      <AlertDialog.Portal>
                        <AlertDialog.Overlay className="fixed bg-black/20 inset-0" />
                        <AlertDialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
                          <AlertDialog.Title className="flex border-b border-solid border-blue-50 p-4">
                            Delete
                          </AlertDialog.Title>
                          <AlertDialog.Description className="p-4 text-sm text-stone-800">
                            Are you sure you want to delete this?
                          </AlertDialog.Description>
                          <div className="flex justify-end border-t border-solid border-blue-50 p-4 gap-4">
                            <Popover.Close>
                              <AlertDialog.Cancel asChild>
                                <Button
                                  disabled={isDeleting}
                                  isLoading={isDeleting}
                                >
                                  Cancel
                                </Button>
                              </AlertDialog.Cancel>
                            </Popover.Close>
                            <Button
                              onClick={handleTrashClick}
                              disabled={isDeleting}
                              isLoading={isDeleting}
                            >
                              Proceed
                            </Button>
                          </div>
                        </AlertDialog.Content>
                      </AlertDialog.Portal>
                    </AlertDialog.Root>
                  </li>
                </ul>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </Protected>
      </td>
    </tr>
  );
};

export const Resources = ({ setViewData, session }) => {
  const [percent, setPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState({});
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const file = useRef();
  const { id } = useParams();
  const splat = window.location.pathname.replace(
    `${process.env.REACT_APP_URI_BASE_PATH}/resources/${id}`,
    ``,
  );
  const folderPath = `/${splat ? `${splat}/` : ``}`.replace(`//`, `/`);
  const handleUploadClick = async () => {
    file.current.click();
  };
  const cancelFileUpload = useRef();
  const handleUploadCancel = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current();
      setPercent(0);
      setIsUploadLoading(false);
      file.current.value = ``;
    }
  };
  const handleFileChange = async () => {
    setPercent(1);
    setIsUploadLoading(true);

    try {
      await uploadFile({
        id,
        file: file.current.files[0],
        path: folderPath,
        setPercent,
        cancelFileUpload,
        getAccessTokenSilently,
      });
      await fetchProject({
        setProject,
        id,
        setIsLoading,
        /* cache, */ folderPath,
        getAccessTokenSilently,
      });
    } catch (error) {
      console.error(error);
      setIsUploadLoading(false);
      file.current.value = ``;
      alert(`Failed to upload the file. Please try again.`);
      return;
    }

    setIsUploadLoading(false);
    file.current.value = ``;
  };

  useEffect(() => {
    fetchProject({
      setProject,
      id,
      setIsLoading,
      folderPath,
      getAccessTokenSilently,
    });
  }, [setProject, id, folderPath, getAccessTokenSilently]);

  useEffect(() => {
    setViewData({ title: project.title, id });
  }, [setViewData, project, id]);

  return (
    <>
      <div className="flex p-6 flex-col gap-5">
        <ul className="flex gap-2 items-center text-sm text-stone-500">
          <li className="flex gap-1 items-center text-stone-800">
            <Link to="/" className="hover:underline">
              Projects
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link
              to={`/projects/${id}?orgName=${encodeURIComponent(
                session.orgNiceName,
              )}`}
              className="hover:underline"
            >
              {project.title}
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link to={`/resources/${id}`} className="hover:underline">
              Resources
            </Link>
          </li>
          {folderPath
            .split(`/`)
            .filter(Boolean)
            .map((name, index) => (
              <li key={name} className="flex gap-1 items-center">
                <i className="material-symbols-rounded text-xl leading-none">
                  chevron_right
                </i>
                <Link
                  to={`/resources/${id}${folderPath
                    .split(`/`)
                    .slice(0, index + 2)
                    .join(`/`)
                    .replace(/\/$/, ``)}`}
                  className="hover:underline"
                >
                  {decodeURIComponent(name)}
                </Link>
              </li>
            ))}
        </ul>
        <div className="flex grow items-center gap-3">
          <h1 className="text-3xl font-medium">
            {folderPath === `/`
              ? `Resources`
              : decodeURIComponent(folderPath.split(`/`).filter(Boolean).pop())}
          </h1>
          {isLoading && <Loading large />}
          <div className="ml-auto flex items-center gap-5">
            <People projectId={id} orgId={session.orgId} />
            <Share />
            <div className="flex items-center gap-6">
              <ManageDirectory
                id={id}
                path={folderPath}
                onCreated={() =>
                  fetchProject({
                    setProject,
                    id,
                    setIsLoading,
                    /* cache, */ folderPath,
                    getAccessTokenSilently,
                  })
                }
              />
              <input
                type="file"
                ref={file}
                className="hidden"
                onChange={handleFileChange}
              />
              <Button
                icon="add_circle"
                isDisabled={isUploadLoading}
                isLoading={isUploadLoading}
                onClick={handleUploadClick}
              >
                Upload file
              </Button>
            </div>
          </div>
        </div>
      </div>
      <table className="table-auto border-spacing-x-6 border-spacing-y-4">
        <thead className="text-xs font-medium uppercase text-stone-800 border-t border-b border-solid border-blue-50">
          <tr>
            <th className="px-6 py-3 text-left">Name</th>
            <th className="px-6 py-3 text-left">Added by</th>
            <th className="px-6 py-3 text-left">Added</th>
            <th className="px-6 py-3 text-left" colSpan="2">
              Size
            </th>
          </tr>
        </thead>
        <tbody className="py-4 px-6 text-stone-700 text-sm font-normal">
          {percent > 1 && file.current.files[0]?.name && (
            <tr className="border-b border-solid border-blue-50">
              <td className="px-6 py-3" colSpan={5}>
                <div className="flex gap-8">
                  <div className="flex flex-col gap-2 flex-grow">
                    <div className="flex gap-2">
                      <div className="flex-grow">
                        {decodeURIComponent(file.current.files[0]?.name)}
                      </div>
                      <div>{percent}%</div>
                    </div>
                    <div className="h-1 bg-blue-50">
                      <div
                        className="h-1 bg-blue-700"
                        style={{ width: `${percent}%` }}
                      />
                    </div>
                  </div>
                  <button
                    className="flex items-center gap-2"
                    onClick={handleUploadCancel}
                  >
                    <i className="material-symbols-rounded text-2xl">close</i>
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
          )}
          {(project?.project_files || []).map(({ id: fileId, ...rest }) => (
            <Row
              key={fileId}
              id={id}
              fileId={fileId}
              setProject={setProject}
              isDisabled={isUploadLoading}
              setIsLoading={setIsLoading}
              // cache={cache}
              folderPath={folderPath}
              allFiles={project.allFiles}
              {...rest}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
