import { useState } from "react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import * as Popover from "@radix-ui/react-popover";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { useAuth0 } from "@auth0/auth0-react";

import { People } from "../../../../components/people";
import { Protected } from "../../../../components/protected";
import { Button } from "../../../../components/button";

const states = {
  not_started: {
    name: `Not started`,
    bgColor: `bg-[#D9DFFC]`,
    textColor: `text-[#0B1C47]`,
  },
  in_progress: {
    name: `In progress`,
    bgColor: `bg-[#F0DAF9]`,
    textColor: `text-[#000069]`,
  },
  paused: {
    name: `Paused`,
    bgColor: `bg-[#F9F0DA]`,
    textColor: `text-[#C73500]`,
  },
  completed: {
    name: `Completed`,
    bgColor: `bg-[#DAF2F9]`,
    textColor: `text-[#00434F]`,
  },
};

const updateProjectLifecycle = async ({
  state,
  id,
  onSuccess,
  getAccessTokenSilently,
}) => {
  try {
    const respoonse = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/lifecycle`,
      {
        method: `put`,
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        body: JSON.stringify({ state, reason: `Changed in UI` }),
      },
    );

    if (!respoonse.ok) {
      throw new Error();
    }

    onSuccess();
  } catch (_) {
    return;
  }
};

export const Project = ({
  id,
  title,
  state,
  short_description,
  last_updated,
  refetch,
  session,
  handleAmendClick,
}) => {
  const [isDeleting, setIsDeleting] = useState();
  const stateConfig = states[state];
  const { getAccessTokenSilently } = useAuth0();
  const handleArchiveClick = async () => {
    setIsDeleting(true);
    await updateProjectLifecycle({
      state: `archived`,
      id,
      onSuccess: refetch,
      getAccessTokenSilently,
    });
    setIsDeleting(false);
  };

  return (
    <tr className="border-b border-solid border-blue-50">
      <td className="px-6 py-3 min-w-[100%] max-w-[11rem] flex gap-2 flex-col">
        <Link
          className="hover:underline"
          to={`/projects/${id}?orgName=${encodeURIComponent(
            session.orgNiceName,
          )}`}
        >
          {title}
        </Link>
        <div className="text-xs line-clamp-3" title={short_description}>
          {short_description}
        </div>
      </td>
      <td className="px-6 py-3">
        {formatDistance(new Date(last_updated), new Date(), {
          addSuffix: true,
        })}
      </td>
      <td className="px-6 py-3">
        <People projectId={id} orgId={session.orgId} />
      </td>
      <td className="px-6 py-3">
        <Popover.Root>
          <Popover.Trigger
            className={`rounded font-medium px-1 py-0.5 inline-block text-xs ${stateConfig.bgColor} ${stateConfig.textColor}`}
          >
            {stateConfig.name}
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className="rounded-lg shadow-3xl p-2 bg-white min-w-[12rem]"
              collisionPadding={16}
              sideOffset={8}
            >
              <ul className="flex flex-col gap-1">
                <li className="text-sm p-2 text-stone-400">Status</li>
                {Object.entries(states).map(
                  ([key, { name, bgColor, textColor }]) => (
                    <li key={key}>
                      <button
                        className={`flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium ${
                          name === stateConfig.name ? `bg-stone-50` : ``
                        }`}
                        onClick={() =>
                          updateProjectLifecycle({
                            state: key,
                            id,
                            onSuccess: refetch,
                            getAccessTokenSilently,
                          })
                        }
                      >
                        <div
                          className={`${bgColor} w-1.5 h-1.5 rounded-full`}
                        ></div>
                        <div>{name}</div>
                        {name === stateConfig.name && (
                          <i className="material-symbols-rounded text-xl flex grow justify-end leading-none">
                            check
                          </i>
                        )}
                      </button>
                    </li>
                  ),
                )}
              </ul>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </td>
      <td className="px-6 py-3 text-right">
        <Popover.Root>
          <Popover.Trigger>
            <i className="material-symbols-rounded text-xl text-stone-600">
              more_horiz
            </i>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className="rounded-lg shadow-3xl p-2 bg-white min-w-[12rem]"
              collisionPadding={16}
              sideOffset={8}
            >
              <ul>
                <li>
                  <Link
                    to={`/projects/${id}?orgName=${encodeURIComponent(
                      session.orgNiceName,
                    )}`}
                    className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium hover:underline"
                  >
                    View
                  </Link>
                </li>
                <Protected requiredRoles={[`Pentatonic`]}>
                  <li>
                    <button
                      onClick={() =>
                        handleAmendClick({ id, title, short_description })
                      }
                      className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium"
                    >
                      Amend
                    </button>
                  </li>
                  <li>
                    <AlertDialog.Root>
                      <AlertDialog.Trigger className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium">
                        Archive
                      </AlertDialog.Trigger>
                      <AlertDialog.Portal>
                        <AlertDialog.Overlay className="fixed bg-black/20 inset-0" />
                        <AlertDialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
                          <AlertDialog.Title className="flex border-b border-solid border-blue-50 p-4">
                            Archive
                          </AlertDialog.Title>
                          <AlertDialog.Description className="p-4 text-sm text-stone-800">
                            Are you sure you want to archive this?
                          </AlertDialog.Description>
                          <div className="flex justify-end border-t border-solid border-blue-50 p-4 gap-4">
                            <Popover.Close>
                              <AlertDialog.Cancel asChild>
                                <Button
                                  disabled={isDeleting}
                                  isLoading={isDeleting}
                                >
                                  Cancel
                                </Button>
                              </AlertDialog.Cancel>
                            </Popover.Close>
                            <Button
                              onClick={handleArchiveClick}
                              disabled={isDeleting}
                              isLoading={isDeleting}
                            >
                              Proceed
                            </Button>
                          </div>
                        </AlertDialog.Content>
                      </AlertDialog.Portal>
                    </AlertDialog.Root>
                  </li>
                </Protected>
              </ul>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </td>
    </tr>
  );
};
