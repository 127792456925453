import { createContext, useContext } from "react";

import { Loading } from "../loading";

export const PermissionsContext = createContext();

export const Protected = ({ children, requiredRoles, fallback = null }) => {
  const permissions = useContext(PermissionsContext);

  if (!permissions) {
    return <Loading />;
  }

  if (!requiredRoles.some((permission) => permissions.includes(permission))) {
    return fallback;
  }

  return children;
};
