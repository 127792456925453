import styled, { keyframes } from "styled-components";

const spin = keyframes`
  100% {
     transform: rotate(1turn);
  }
`;

export const StyledLoading = styled.div`
  animation: ${spin} 1s infinite linear;
  border-radius: 50%;
  background:
    radial-gradient(
        farthest-side,
        ${({ $neutral }) => ($neutral ? `white` : `#17e0c2`)} 100%,
        transparent
      )
      top/0.0625rem 0.0625rem no-repeat,
    conic-gradient(
      #0000 20%,
      ${({ $neutral }) => ($neutral ? `white` : `#17e0c2`)}
    );
  height: 1rem;
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 0.0625rem),
    #000 0
  );
  width: 1rem;
  zoom: ${({ $large }) => ($large ? `175%` : `100%`)};
`;
