import { Loading } from "../loading";

export const Button = ({
  icon,
  isLoading = false,
  isDisabled = false,
  type = `button`,
  onClick = () => {},
  children,
  secondary,
}) => {
  return (
    <button
      className={`${
        secondary
          ? `bg-white text-stone-800 border border-stone-800 font-normal`
          : `bg-blue-700 text-white font-medium hover:bg-blue-800`
      }  rounded-lg px-4 py-2 text-sm flex gap-3 items-center justify-center min-h-[2.25rem] disabled:bg-stone-200`}
      type={type}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <Loading neutral />
      ) : (
        <>
          {Boolean(icon) && (
            <i className="material-symbols-rounded text-lg leading-none">
              {icon}
            </i>
          )}
          {children}
        </>
      )}
    </button>
  );
};
