import { useEffect, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { useAuth0 } from "@auth0/auth0-react";
import * as Dialog from "@radix-ui/react-dialog";
import { Loading } from "../../components/loading";
import { UploadFile } from "../../components/upload-file";
import { Protected } from "../../components/protected/protected.js";
import { Button } from "../../components/button/button.js";

const shippingUpdates = [
  {
    name: `Preparing Material`,
    value: `{{preparing-material}}`,
    className: `w-[calc(0-1.25rem)]`,
  },
  {
    name: `Preparing Shipment`,
    value: `{{preparing-shipment}}`,
    className: `w-[calc(37.5%-1.25rem)]`,
  },
  {
    name: `In Transit`,
    value: `{{in-transit}}`,
    className: `w-[calc(62.5%-1.25rem)]`,
  },
  {
    name: `Delivered`,
    value: `{{delivered}}`,
    className: `w-[calc(100%-1.25rem)]`,
  },
];

const fetchProject = async ({
  setProject,
  id,
  setIsLoading,
  getAccessTokenSilently,
}) => {
  setIsLoading(true);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const project = await response.json();

    setProject(project);
    setIsLoading(false);
  } catch (_) {
    setIsLoading(false);
  }
};

const fetchProjects = async ({
  setProject,
  setIsLoading = () => {},
  getAccessTokenSilently,
}) => {
  let projects;
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error(`Projects: Response not OK`);
    }

    ({ projects } = await response.json());
  } catch (_) {
    projects = [];
  }

  const foundProject = projects.find((project) =>
    project.title.startsWith(`{{MP}}_`),
  );

  if (foundProject) {
    await fetchProject({
      setProject,
      id: foundProject.id,
      setIsLoading,
      getAccessTokenSilently,
    });
  }

  setIsLoading(false);
};

const updateProject = async ({ fields, session, getAccessTokenSilently }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects`,
      {
        method: `POST`,
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        body: JSON.stringify({
          ...fields,
          title: `{{MP}}_${fields.title}`,
          org_id: session.orgId,
        }),
      },
    );

    if (!response.ok) {
      throw new Error();
    }
  } catch (_) {
    return false;
  }

  return true;
};

const QUOTE_DIRECTORY_BASE_PATH = `/bh8rhd-system-mp-bh8rhd-quote`;
const PURCHASE_ORDER_DIRECTORY_BASE_PATH = `/bh8rhd-system-mp-bh9rhd-purchase`;
const INVOICE_DIRECTORY_BASE_PATH = `/bh8rhd-system-mp-bh1rhd-invoice`;
const PAYMENT_DIRECTORY_BASE_PATH = `/bh8rhd-system-mp-bh2rhd-payment`;
const SHIPPING_STATUS_DIRECTORY_BASE_PATH = `/bh8rhd-system-mp-bh3rhd-shipping`;

export const MaterialsProcurement = ({ session }) => {
  const stages = [
    {
      qualifier: ({ allFiles }) =>
        !allFiles.find(
          ({ folder_path }) => folder_path === `${QUOTE_DIRECTORY_BASE_PATH}/`,
        ),
      nextActionName: `Quote`,
      nextActionId: `${QUOTE_DIRECTORY_BASE_PATH}`,
      nextActionKey: `mp-quote`,
      pillText: `Request Sent`,
      pillStyles: `bg-[#D9DFFC] text-[#0B1C47]`,
      nextActionAllowed: session.isInternal,
    },
    {
      qualifier: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) => folder_path === `${QUOTE_DIRECTORY_BASE_PATH}/`,
        ),
      latestDocumentName: `quote.pdf`,
      buildLatestDocument: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) => folder_path === `${QUOTE_DIRECTORY_BASE_PATH}/`,
        ),
      nextActionName: `Purchase Order`,
      nextActionId: `${PURCHASE_ORDER_DIRECTORY_BASE_PATH}`,
      nextActionKey: `mp-purchase`,
      optionsEnabled: [`quote`],
      pillText: `Quote Ready`,
      pillStyles: `bg-[#D9DFFC] text-[#0B1C47]`,
      nextActionAllowed: !session.isInternal,
    },
    {
      qualifier: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) =>
            folder_path === `${PURCHASE_ORDER_DIRECTORY_BASE_PATH}/`,
        ),
      latestDocumentName: `purchase-order.pdf`,
      buildLatestDocument: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) =>
            folder_path === `${PURCHASE_ORDER_DIRECTORY_BASE_PATH}/`,
        ),
      nextActionName: `Invoice`,
      nextActionId: `${INVOICE_DIRECTORY_BASE_PATH}`,
      nextActionKey: `mp-invoice`,
      optionsEnabled: [`quote`, `purchase`],
      pillText: `Awaiting Invoice`,
      pillStyles: `bg-[#D9DFFC] text-[#0B1C47]`,
      nextActionAllowed: session.isInternal,
    },
    {
      qualifier: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) =>
            folder_path === `${INVOICE_DIRECTORY_BASE_PATH}/`,
        ),
      latestDocumentName: `invoice.pdf`,
      buildLatestDocument: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) =>
            folder_path === `${PAYMENT_DIRECTORY_BASE_PATH}/`,
        ),
      nextActionName: `Payment Confirmation`,
      nextActionId: `${PAYMENT_DIRECTORY_BASE_PATH}`,
      nextActionKey: `mp-payment`,
      optionsEnabled: [`quote`, `purchase`, `invoice`],
      pillText: `Invoice Ready`,
      pillStyles: `bg-[#D9DFFC] text-[#0B1C47]`,
      nextActionAllowed: !session.isInternal,
    },
    {
      qualifier: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) =>
            folder_path === `${PAYMENT_DIRECTORY_BASE_PATH}/`,
        ),
      latestDocumentName: `payment-confirmation.pdf`,
      buildLatestDocument: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) =>
            folder_path === `${SHIPPING_STATUS_DIRECTORY_BASE_PATH}/`,
        ),
      nextActionName: `Shipping Status`,
      nextActionId: `${SHIPPING_STATUS_DIRECTORY_BASE_PATH}`,
      nextActionKey: `mp-shipping`,
      optionsEnabled: [`quote`, `purchase`, `invoice`, `payment`],
      pillText: `Awaiting Shipping Information`,
      pillStyles: `bg-[#D9DFFC] text-[#0B1C47]`,
      nextActionAllowed: session.isInternal,
    },
    {
      qualifier: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) =>
            folder_path === `${SHIPPING_STATUS_DIRECTORY_BASE_PATH}/`,
        ),
      latestDocumentName: `shipping-status.pdf`,
      buildLatestDocument: ({ allFiles }) =>
        allFiles.find(
          ({ folder_path }) =>
            folder_path === `${SHIPPING_STATUS_DIRECTORY_BASE_PATH}/`,
        ),
      nextActionName: `Shipping Status`,
      nextActionId: `${SHIPPING_STATUS_DIRECTORY_BASE_PATH}`,
      nextActionKey: `mp-shipping-update`,
      optionsEnabled: [`quote`, `purchase`, `invoice`, `payment`, `shipping`],
      operationTypeName: `Update`,
      pillText: `Preparing Material`,
      pillStyles: `bg-[#D9DFFC] text-[#0B1C47]`,
      nextActionAllowed: session.isInternal,
    },
  ];
  const options = [
    {
      key: `quote`,
      name: `Download Quote`,
      id: `${QUOTE_DIRECTORY_BASE_PATH}`,
    },
    {
      key: `purchase`,
      name: `Download Purchase Order`,
      id: `${PURCHASE_ORDER_DIRECTORY_BASE_PATH}`,
    },
    {
      key: `invoice`,
      name: `Download Invoice`,
      id: `${INVOICE_DIRECTORY_BASE_PATH}`,
    },
    {
      key: `payment`,
      name: `Download Payment Confirmation`,
      id: `${PAYMENT_DIRECTORY_BASE_PATH}`,
    },
    {
      key: `shipping`,
      name: `Download Shipping Status`,
      id: `${SHIPPING_STATUS_DIRECTORY_BASE_PATH}`,
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const defaultFields = { title: ``, short_description: `` };
  const [fields, setFields] = useState(defaultFields);
  const handleChange = (name, value) => {
    setFields((previousFields) => ({ ...previousFields, [name]: value }));
  };
  const allFiles = project?.project_files || [];
  const {
    latestDocumentName,
    buildLatestDocument,
    nextActionName,
    nextActionId,
    nextActionKey,
    optionsEnabled,
    pillText,
    pillStyles,
    nextActionAllowed,
    operationTypeName = `Add`,
  } = stages.findLast(({ qualifier }) => qualifier({ allFiles }));
  const handleUpdateProject = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const updated = await updateProject({
      fields,
      session,
      getAccessTokenSilently,
    });
    if (updated) {
      fetchProjects({ setProject, setIsLoading, getAccessTokenSilently });
      setIsLoading(false);
      setModalOpen(false);
      setFields(defaultFields);
    }
  };
  const shippingUpdateIndex = shippingUpdates.findIndex(
    ({ value }) =>
      buildLatestDocument &&
      buildLatestDocument({ allFiles })?.name?.includes(value),
  );

  useEffect(() => {
    fetchProjects({ setProject, setIsLoading, getAccessTokenSilently });
  }, [getAccessTokenSilently]);

  return (
    <>
      <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
        <div className="flex p-6">
          <div className="flex grow items-center gap-3">
            <h1 className="text-3xl font-medium">Materials Procurement</h1>
            {isLoading && <Loading large />}
          </div>
          <Protected requiredRoles={[`Pentatonic`]}>
            <Dialog.Trigger asChild>
              <Button icon="add_circle" isDisabled={project}>
                Add Request
              </Button>
            </Dialog.Trigger>
          </Protected>
        </div>
        <table className="table-auto border-spacing-x-6 border-spacing-y-4">
          <thead className="text-xs font-medium uppercase text-stone-800 border-t border-b border-solid border-blue-50">
            <tr>
              <th className="px-6 py-3 text-left">Material</th>
              <th className="px-6 py-3 text-left">Quantity</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-left">Latest document</th>
              <th className="px-6 py-3 text-left" colSpan="2">
                Next action
              </th>
            </tr>
          </thead>
          <tbody className="py-4 px-6 text-stone-700 text-sm font-normal">
            {project && (
              <tr className="border-b border-solid border-blue-50">
                <td className="px-6 py-3">
                  {project.title.replace(`{{MP}}_`, ``)}
                </td>
                <td className="px-6 py-3">{project.short_description}</td>
                <td className="px-6 py-3">
                  <div
                    className={`rounded font-medium px-1 py-0.5 inline-block text-xs ${pillStyles}`}
                  >
                    <div>{pillText}</div>
                  </div>
                </td>
                <td className="px-6 py-3">
                  {latestDocumentName ? (
                    <a
                      className="text-blue-700"
                      href={`${
                        process.env.REACT_APP_URI_BASE_PATH
                      }/api/projects/${project.id}/files/${buildLatestDocument({ allFiles })?.id}`}
                    >
                      {latestDocumentName}
                    </a>
                  ) : (
                    `-`
                  )}
                </td>
                <td className="px-6 py-3">
                  {nextActionAllowed ? (
                    <UploadFile
                      isExisting={operationTypeName === `Update`}
                      basePath={nextActionId}
                      type={nextActionKey}
                      id={project.id}
                      name={nextActionName}
                      trigger={
                        <button className="text-blue-700">
                          {operationTypeName} {nextActionName}
                        </button>
                      }
                      onFileUploaded={async () =>
                        await fetchProject({
                          setProject,
                          id: project.id,
                          setIsLoading,
                          getAccessTokenSilently,
                        })
                      }
                      filePrefixList={
                        [
                          `payment-confirmation.pdf`,
                          `shipping-status.pdf`,
                        ].includes(latestDocumentName) && shippingUpdates
                      }
                      currentFile={buildLatestDocument({ allFiles })}
                    />
                  ) : (
                    `-`
                  )}
                </td>
                <td className="px-6 py-3 text-right">
                  <Popover.Root>
                    <Popover.Trigger>
                      <i className="material-symbols-rounded text-xl text-stone-600">
                        more_horiz
                      </i>
                    </Popover.Trigger>
                    <Popover.Portal>
                      <Popover.Content
                        className="rounded-lg shadow-3xl p-2 bg-white min-w-[12rem]"
                        collisionPadding={16}
                        sideOffset={8}
                      >
                        <ul>
                          <li className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm font-medium">
                            Options
                          </li>
                          {options.map(({ key, name, id: optionId }) => {
                            const fileId = allFiles.find(
                              ({ folder_path }) =>
                                folder_path === `${optionId}/`,
                            )?.id;

                            return (
                              <li key={key}>
                                <a
                                  className={`flex p-2 w-full items-center gap-3 text-left text-sm rounded-sm ${
                                    !optionsEnabled?.includes(key)
                                      ? `text-stone-300 pointer-events-none`
                                      : `text-stone-800`
                                  }`}
                                  href={`${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${project.id}/files/${fileId}`}
                                >
                                  {name}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </Popover.Content>
                    </Popover.Portal>
                  </Popover.Root>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {latestDocumentName === `shipping-status.pdf` && (
          <div className="p-5 border-b border-solid border-blue-50">
            <div className="text-sm font-medium mb-5">Shipping Progress</div>
            <div className="flex gap-80 relative">
              <div className="bg-blue-50 w-[calc(100%-1.25rem)] h-1 absolute top-2 left-5 z-[-1] mr-5"></div>
              <div
                className={`bg-blue-700 h-1 absolute top-2 left-5 z-[-1] mr-5 ${shippingUpdates[shippingUpdateIndex]?.className}`}
              ></div>
              <ul className="flex gap-4 w-full justify-between">
                {shippingUpdates.map(({ name, value }, index) => {
                  const isActive = index <= shippingUpdateIndex;

                  return (
                    <li
                      className="flex w-full flex-col gap-4 items-center first:items-start last:items-end"
                      key={value}
                    >
                      <div
                        className={`rounded-full w-5 h-5 border-2 border-white ${isActive ? `bg-blue-700` : `bg-blue-50`}`}
                      ></div>
                      <div
                        className={`text-xs text-stone-700 ${isActive ? `font-medium` : ``}`}
                      >
                        {name}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        <Dialog.Portal>
          <Dialog.Overlay className="fixed bg-black/20 inset-0" />
          <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem]">
            <div className="flex border-b border-solid border-blue-50 p-4">
              <Dialog.Title className="flex grow text-lg">
                Add Request
              </Dialog.Title>
              <Dialog.Close tabIndex="-1">
                <i className="material-symbols-rounded text-xl">close</i>
              </Dialog.Close>
            </div>
            <form onSubmit={handleUpdateProject}>
              <div className="flex flex-col p-4 gap-4">
                <div className="flex flex-col gap-1">
                  <label
                    className="text-sm text-stone-800"
                    htmlFor="add-project_name"
                  >
                    Material
                  </label>
                  <input
                    type="text"
                    placeholder="Material of the request"
                    id="add-project_name"
                    required
                    onChange={(event) =>
                      handleChange(`title`, event.target.value)
                    }
                    value={fields.title}
                    className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                    maxLength="256"
                    tabIndex="0"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    className="text-sm text-stone-800"
                    htmlFor="add-project_description"
                  >
                    Quantity
                  </label>
                  <input
                    type="text"
                    placeholder="Quantity of the request"
                    id="add-project_description"
                    required
                    onChange={(event) =>
                      handleChange(`short_description`, event.target.value)
                    }
                    value={fields.short_description}
                    className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300"
                    maxLength="256"
                    tabIndex="0"
                  />
                </div>
              </div>
              <div className="flex justify-end border-t border-solid border-blue-50 p-4">
                <Button
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Add Request
                </Button>
              </div>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
