import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as Select from "@radix-ui/react-select";
import { useAuth0 } from "@auth0/auth0-react";

import { Loading } from "../loading";
import { Button } from "../button";
import { Protected } from "../protected";

const fetchOrgPeople = async ({
  setOrgPeople,
  orgId,
  setIsLoading,
  getAccessTokenSilently,
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/orgs/${orgId}/members`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const projectPeople = await response.json();

    setOrgPeople(projectPeople.members || []);
  } catch (_) {
    setIsLoading(false);
    return;
  }

  setIsLoading(false);
};

const fetchProjectPeople = async ({
  setProjectPeople,
  projectId,
  setIsLoading,
  getAccessTokenSilently,
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/members`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const projectPeople = await response.json();

    setProjectPeople(projectPeople?.members || []);
  } catch (_) {
    setIsLoading(false);
    return;
  }

  setIsLoading(false);
};

export const People = ({ projectId, orgId }) => {
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [orgPeople, setOrgPeople] = useState([]);
  const [projectPeople, setProjectPeople] = useState([]);
  const [selectedId, setSelectedId] = useState(``);
  const [modalOpen, setModalOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const add = async (sub) => {
    setSelectedId(sub);

    try {
      const email = orgPeople.find((person) => person.sub === sub).email;

      await fetch(
        `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/members`,
        {
          method: `PUT`,
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
          body: JSON.stringify({
            members: [
              {
                org_id: orgId,
                auth0_id: sub,
                email,
              },
            ],
          }),
        },
      );

      await fetchProjectPeople({
        setProjectPeople,
        projectId,
        setIsLoading: setIsLoading2,
        getAccessTokenSilently,
      });
    } catch (_) {
      setSelectedId(``);
      return;
    }

    setSelectedId(``);
  };
  const deletePerson = async (id) => {
    try {
      await fetch(
        `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${projectId}/members/${id}`,
        {
          method: `DELETE`,
          headers: {
            "Content-Type": `application/json`,
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
      );

      await fetchProjectPeople({
        setProjectPeople,
        projectId,
        setIsLoading: setIsLoading2,
        getAccessTokenSilently,
      });
    } catch (_) {
      return;
    }
  };
  const limit = 5;

  useEffect(() => {
    fetchOrgPeople({
      setOrgPeople,
      setIsLoading: setIsLoading1,
      orgId,
      getAccessTokenSilently,
    });
    fetchProjectPeople({
      setProjectPeople,
      projectId,
      setIsLoading: setIsLoading2,
      getAccessTokenSilently,
    });
  }, [getAccessTokenSilently, orgId, projectId]);

  if (isLoading1 || isLoading2) {
    return <Loading />;
  }

  return (
    <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
      <Dialog.Trigger asChild>
        <div>
          {!projectPeople.filter(({ avatar }) => avatar).length ? (
            <div className="flex items-center gap-2 cursor-pointer text-sm">
              <Protected requiredRoles={[`Pentatonic`]}>
                <div className="w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center text-stone-700 opacity-30 font-semibold">
                  <i className="material-symbols-rounded text-xl">add</i>
                </div>
              </Protected>
              <div>No members</div>
            </div>
          ) : (
            <div className="flex gap-3 items-center text-stone-700 font-medium cursor-pointer">
              {projectPeople
                .slice(0, limit)
                .filter(({ avatar }) => avatar)
                .map(({ avatar, name }) => (
                  <img
                    key={avatar}
                    src={avatar}
                    alt={name}
                    className="w-6 h-6 rounded-full"
                  />
                ))}
              {projectPeople.length > limit && (
                <div className="text-xs">+ {projectPeople.length - limit}</div>
              )}
            </div>
          )}
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed bg-black/20 inset-0" />
        <Dialog.Content className="fixed bg-white top-2/4 left-2/4 rounded-lg shadow-3xl translate-x-[-50%] translate-y-[-50%] min-w-[34rem] z-10">
          <div className="flex p-4 items-start">
            <div className="flex grow flex-col">
              <Dialog.Title className="text-lg">Project Members</Dialog.Title>
            </div>
            <Dialog.Close tabIndex="-1">
              <i className="material-symbols-rounded text-xl">close</i>
            </Dialog.Close>
          </div>
          <div className="px-6 flex gap-5 flex-col">
            <Protected requiredRoles={[`Pentatonic`]}>
              <div className="flex flex-col gap-1">
                <label className="text-sm text-stone-800" htmlFor="add_members">
                  Add Members
                </label>
                <Select.Root
                  onValueChange={(value) => add(value)}
                  value={selectedId}
                  required
                >
                  <Select.Trigger
                    aria-label="Select member"
                    className="rounded-md border border-solid border-stone-300 px-2.5 py-2 text-sm text-stone-800 placeholder:text-stone-300 flex justify-between items-center"
                  >
                    <Select.Value placeholder="Please select" />
                    <Select.Icon className="flex">
                      <i className="material-symbols-rounded text-xl text-stone-600 leading-none">
                        expand_more
                      </i>
                    </Select.Icon>
                  </Select.Trigger>
                  <Select.Portal className="z-10">
                    <Select.Content>
                      <Select.ScrollUpButton>
                        <i className="material-symbols-rounded text-xl text-stone-600">
                          expand_less
                        </i>
                      </Select.ScrollUpButton>
                      <Select.Viewport className="bg-white rounded-lg shadow-3xl border border-blue-50">
                        <Select.Item
                          value=""
                          className="p-2 text-sm flex items-center gap-2 cursor-pointer"
                        >
                          <Select.ItemText>Please select</Select.ItemText>
                        </Select.Item>
                        {orgPeople
                          .filter(
                            (person) =>
                              !projectPeople
                                .map((projectPerson) => projectPerson.sub)
                                .includes(person.sub),
                          )
                          .map(({ name, email, sub }) => (
                            <Select.Item
                              key={sub}
                              value={sub}
                              className="p-2 text-sm flex items-center gap-2 cursor-pointer"
                            >
                              <Select.ItemText>
                                {name} - {email}
                              </Select.ItemText>
                              <Select.ItemIndicator className="flex">
                                <i className="material-symbols-rounded text-sm text-stone-600">
                                  done
                                </i>
                              </Select.ItemIndicator>
                            </Select.Item>
                          ))}
                      </Select.Viewport>
                      <Select.ScrollDownButton>
                        <i className="material-symbols-rounded text-xl text-stone-600">
                          expand_more
                        </i>
                      </Select.ScrollDownButton>
                    </Select.Content>
                  </Select.Portal>
                </Select.Root>
              </div>
            </Protected>
            <ul className="flex flex-col gap-1">
              {!projectPeople.length && (
                <li className="flex p-1 gap-2 items-center justify-center text-sm group">
                  <div className="flex-grow font-normal text-stone-800">
                    No members
                  </div>
                </li>
              )}
              {projectPeople.map(({ avatar, name, id, email }) => (
                <li
                  key={avatar}
                  className="flex p-1 gap-2 items-center justify-center hover:bg-stone-50 text-sm group"
                >
                  {avatar && (
                    <img
                      src={avatar}
                      alt={name}
                      className="w-6 h-6 rounded-full"
                    />
                  )}
                  <div className="flex-grow font-normal text-stone-800">
                    {name || email}
                  </div>
                  <Protected requiredRoles={[`Pentatonic`]}>
                    <button
                      className="flex hidden group-hover:flex"
                      onClick={() => deletePerson(id)}
                    >
                      <i className="material-symbols-rounded text-xl leading-none">
                        close
                      </i>
                    </button>
                  </Protected>
                </li>
              ))}
            </ul>
          </div>
          <div className="p-6 flex justify-end">
            <Dialog.Close asChild>
              <Button>Done</Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
