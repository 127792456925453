import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { PdfViewer } from "../../components/pdf-viewer";
import { People } from "../../components/people";
import { Share } from "../../components/share";
import { Loading } from "../../components/loading";
import { Protected } from "../../components/protected/protected.js";

import { UploadFile } from "../../components/upload-file";

import { ReactComponent as SowImage } from "./sow.svg";

const fetchProject = async ({
  setProject,
  id,
  setIsLoading,
  getAccessTokenSilently,
  onError = () => {},
}) => {
  setIsLoading(true);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}`,
      {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      },
    );
    const project = await response.json();

    if (response.status === 404) {
      onError();
    }

    setProject(project);
    setIsLoading(false);
  } catch (_) {
    setIsLoading(false);
  }
};

export const Project = ({ setViewData, cache, session, onError }) => {
  const [project, setProject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const sowDirectoryPathBase = `/ah7rhd-sow`;
  const latestSow = (project?.project_files || []).find(
    ({ folder_path }) => folder_path === `${sowDirectoryPathBase}/`,
  );
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchProject({
      setProject,
      id,
      setIsLoading,
      getAccessTokenSilently,
      onError,
    });
  }, [setProject, id, cache, getAccessTokenSilently, onError]);

  useEffect(() => {
    setViewData({ title: project.title, id });
  }, [setViewData, project, id]);

  return (
    <div>
      <div className="flex p-6 flex-col gap-5">
        <ul className="flex gap-2 items-center text-sm text-stone-500">
          <li className="flex gap-1 items-center text-stone-800">
            <Link to="/" className="hover:underline">
              Projects
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link
              to={`/projects/${id}?orgName=${encodeURIComponent(
                session.orgNiceName,
              )}`}
              className="hover:underline"
            >
              {project.title}
            </Link>
          </li>
          <li className="flex gap-1 items-center">
            <i className="material-symbols-rounded text-xl leading-none">
              chevron_right
            </i>
            <Link
              to={`/projects/${id}?orgName=${encodeURIComponent(
                session.orgNiceName,
              )}`}
              className="hover:underline"
            >
              Statement of Work
            </Link>
          </li>
        </ul>
        <div className="flex grow items-center gap-3">
          <h1 className="text-3xl font-medium">Statement of Work</h1>
          {isLoading && <Loading large />}
          <div className="ml-auto flex items-center gap-5">
            <People projectId={id} orgId={session.orgId} />
            {latestSow && (
              <a
                href={`${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${latestSow.id}`}
              >
                <i className="material-symbols-rounded text-xl">download</i>
              </a>
            )}
            <Share />
            <Protected requiredRoles={[`Pentatonic`]}>
              <UploadFile
                type="sow"
                isExisting={Boolean(latestSow)}
                basePath={sowDirectoryPathBase}
                id={id}
                disabled={isLoading}
                name="Statement of Work"
                onFileUploaded={async () =>
                  await fetchProject({
                    setProject,
                    id,
                    setIsLoading,
                    getAccessTokenSilently,
                  })
                }
              />
            </Protected>
          </div>
        </div>
      </div>
      {!isLoading && (
        <>
          {latestSow ? (
            <PdfViewer
              key={latestSow.id}
              url={`${process.env.REACT_APP_URI_BASE_PATH}/api/projects/${id}/files/${latestSow.id}`}
            />
          ) : (
            <div className="flex items-center flex-col gap-3 border-t border-solid border-blue-50 py-16">
              <SowImage className="w-full mb-8" />
              <h2 className="text-stone-800 text-3xl font-medium">
                Statement of Work
              </h2>
              <div className="text-stone-500 text-base">
                The Statement of Work is currently being worked on. Check back
                soon.
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const ProjectNavigation = ({ viewData, session }) => {
  const { pathname } = useLocation();
  const pages = [
    {
      path: `/projects/${viewData.id}?orgName=${encodeURIComponent(
        session.orgNiceName,
      )}`,
      name: `Statement of Work`,
      icon: `flowsheet`,
    },
    { path: `/check-ins/${viewData.id}`, name: `Check-Ins`, icon: `strategy` },
    {
      path: `/pitch-back/${viewData.id}`,
      name: `Pitch Back`,
      icon: `clinical_notes`,
    },
    {
      path: `/resources/${viewData.id}`,
      name: `Resources`,
      icon: `perm_media`,
    },
  ];
  const isCurrentPath = ({ path }) => {
    return (
      pathname.split(`/`).filter(Boolean).shift() ===
      path.split(`/`).filter(Boolean).shift()
    );
  };

  return (
    <>
      <div className="flex gap-4 items-center">
        <Link to="/" className="flex">
          <i className="material-symbols-rounded text-xl leading-none text-stone-500 hover:text-blue-500">
            arrow_left_alt
          </i>
        </Link>
        <div>{viewData.title}</div>
      </div>
      <ul className="leading-tight text-sm font-medium flex flex-col gap-2">
        {pages.map(({ path, name, icon }) => (
          <li key={name}>
            <Link
              className={`p-2 rounded w-full flex items-center gap-3 hover:bg-blue-50 hover:text-blue-500 group ${
                isCurrentPath({ path }) ? `bg-blue-50 text-blue-500` : ``
              }`}
              to={path}
            >
              <i
                className={`material-symbols-rounded text-xl group-hover:text-blue-500  ${
                  isCurrentPath({ path }) ? `text-blue-500` : ``
                }`}
              >
                {icon}
              </i>
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};
